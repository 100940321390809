export const authRoles = {
    sa: ['Super Admin'], // Only Super Admin has access
    admin: ['Super Admin', 'Admin'], // Only SA & Admin has access

    dashboard: ['Developer', 'Super Admin', 'Commisioner', 'Duty Officer', 'Front Desk', 'Hotline Officer', 'I&I Devision Management Assitants', 'I&I Director and Deputy Director', 'Legal Officer', 'Management Assitant', 'Regional Office Coordinator', 'Regional Office Management Assitant', 'Summons Section'],
    datasetup: ['Developer', 'Super Admin'],
    createComplaint: ['Developer', 'Duty Officer', 'Hotline Officer', 'Super Admin'],
    temporrycases: ['Developer', 'Super Admin', 'Commisioner', 'Duty Officer', 'Front Desk', 'Hotline Officer', 'I&I Devision Management Assitants', 'I&I Director and Deputy Director', 'Legal Officer', 'Management Assitant', 'Regional Office Coordinator', 'Regional Office Management Assitant', 'Summons Section'],

    directorAllCases: ['Developer', 'Commisioner', 'I&I Director and Deputy Director', 'Super Admin','Regional Office Coordinator'],
    directorCanCannotprocess: ['Developer', 'Commisioner', 'I&I Director and Deputy Director', 'Super Admin','Regional Office Coordinator'],
    directorCanCase: ['Developer', 'Commisioner', 'I&I Director and Deputy Director', 'Super Admin','Regional Office Coordinator'],
    directorCannotCase: ['Developer', 'Commisioner', 'I&I Director and Deputy Director', 'Super Admin','Regional Office Coordinator'],
    directorFocalPointCase: ['Developer', 'Commisioner', 'I&I Director and Deputy Director', 'Super Admin','Regional Office Coordinator'],
    directorMarkedCan: ['Developer', 'Commisioner', 'I&I Director and Deputy Director', 'Super Admin','Regional Office Coordinator'],
    directorMarkedCannot: ['Developer', 'Commisioner', 'I&I Director and Deputy Director', 'Super Admin','Regional Office Coordinator'],
    directorRefFocalpoint: ['Developer', 'Commisioner', 'I&I Director and Deputy Director', 'Super Admin','Regional Office Coordinator'],

    MaAllCases: ['Developer', 'Front Desk', 'Duty Officer', 'Hotline Officer', 'Management Assitant', 'Regional Office Management Assistant', 'I&I Devision Management Assitants'],
    MaNewCaseDetails: ['Developer', 'Front Desk', 'Duty Officer', 'Hotline Officer', 'Legal Officer', 'Management Assitant', 'Regional Office Management Assistant', 'I&I Devision Management Assitants'],
    MaMarkedasCan: ['Developer', 'Front Desk', 'Duty Officer', 'Hotline Officer', 'Legal Officer', 'Management Assitant', 'Regional Office Management Assistant', 'I&I Devision Management Assitants'],
    MaMarkedasCannot: ['Developer', 'Front Desk', 'Duty Officer', 'Hotline Officer', 'Legal Officer', 'Management Assitant', 'Regional Office Management Assistant', 'I&I Devision Management Assitants'],
    MaFocalPoints: ['Developer', 'Front Desk', 'Duty Officer', 'Hotline Officer', 'Management Assitant', 'Regional Office Management Assistant', 'I&I Devision Management Assitants'],

    OfficerAcceptCase: ['Developer', 'Legal Officer', 'Duty Officer'],
    OfficerViewCases: ['Developer', 'Legal Officer', 'Duty Officer'],

    CaseRegister: ['Developer', 'Super Admin', 'Commisioner', 'Duty Officer', 'Front Desk', 'Hotline Officer', 'I&I Devision Management Assitants', 'I&I Director and Deputy Director', 'Legal Officer', 'Management Assitant', 'Regional Office Coordinator', 'Regional Office Management Assitant', 'Summons Section'],
    SummonSection: ['Developer', 'Super Admin', 'Summons Section'],
    AppealAll: ['Developer', 'Super Admin', 'Commisioner', 'I&I Director and Deputy Director', 'Regional Office Coordinator'],
    AppealOfficer: ['Developer', 'Front Desk', 'Duty Officer', 'Hotline Officer', 'Legal Officer'],
    AppealView: ['Developer', 'Front Desk', 'Duty Officer', 'Hotline Officer', 'Legal Officer', 'Super Admin', 'Commisioner', 'I&I Director and Deputy Director'],
    NonImp: ['Developer', 'Super Admin', 'Commisioner', 'I&I Director and Deputy Director', 'Non Implementation Unit Officer', 'Regional Office Coordinator', ],
    all: ['Commisioner', 'Developer', 'Duty Officer', 'File Manager', 'Front Desk', 'Hotline Officer', 'I&I Devision Management Assitants', 'I&I Director and Deputy Director', 'Legal Officer', 'Management Assitant', 'Non Implementation Unit Officer', 'Record Room Officer', 'Regional Office Coordinator', 'Regional Office Management Assistant', 'Scheduling Officer', 'Stenographer', 'Summons Section', 'Super Admin'],
    schedule: ['Developer', 'Super Admin', 'Commisioner', 'Scheduling Officer', 'I&I Director and Deputy Director', 'Regional Office Coordinator'],
    report: ['Developer', 'Super Admin', 'Legal Officer', 'Management Assitant'],
    report_top: ['Developer', 'Super Admin', 'Commisioner', 'I&I Director and Deputy Director', 'Regional Office Coordinator', ],
    visit_guide_line: ['Developer', 'Super Admin', 'I&I Director and Deputy Director', 'Regional Office Coordinator', 'Commisioner'],
    sumotto: ['Developer', 'Duty Officer', 'Hotline Officer', 'Super Admin', 'I&I Director and Deputy Director', 'Regional Office Coordinator'],
    record_room: ['Developer', 'Super Admin', 'Commisioner', 'I&I Director and Deputy Director', 'Regional Office Coordinator', 'Record Room Officer'],
}

// Check out app/views/dashboard/DashboardRoutes.js
// Only SA & Admin has dashboard access

// const dashboardRoutes = [
//   {
//     path: "/dashboard/analytics",
//     component: Analytics,
//     auth: authRoles.admin <----------------
//   }
// ];