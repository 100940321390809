import React from 'react'
import { authRoles } from '../../auth/authRoles'
import { MatxLoadable } from "matx";


const SearchScreen = MatxLoadable({
    loader: () =>
        import ("./SearchScreen")
})

const SearchRoutes = [
    {
        path: '/searching_screen',
        component: SearchScreen,
        auth: authRoles.dashboard,
    },
]

export default SearchRoutes