import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const AllCases = MatxLoadable({
    loader: () =>
        import ("./All")
})
const Callings = MatxLoadable({
    loader: () =>
        import ("./Callings")
})
const CallingsOfficer = MatxLoadable({
    loader: () =>
        import ("./CallingsOfficer")
})
const Recommendation = MatxLoadable({
    loader: () =>
        import ("./Recommendation")
})
const RecommendationOfficer = MatxLoadable({
    loader: () =>
        import ("./RecommendationOfficer")
})
const Summon = MatxLoadable({
    loader: () =>
        import ("./Summon")
})
const SummonOfficer = MatxLoadable({
    loader: () =>
        import ("./SummonOfficer")
})

const Summary = MatxLoadable({
    loader: () =>
        import ("./Summary")
})

const StatusOfCasesAgainstOfficersReport = MatxLoadable({
    loader: () =>
        import ("./StatusOfCasesAgainstOfficersReport")
})
const NoOfCasesForYearAgainstCategoryReport = MatxLoadable({
    loader: () =>
        import ("./NoOfCasesForYearAgainstCategoryReport")
})
const NoOfCasesForYearAgainstCategorySub = MatxLoadable({
    loader: () =>
        import ("./NoOfCasesForYearAgainstCategorySub")
})
const NoOfPendingCasesByCategoryAgainstOfficer = MatxLoadable({
    loader: () =>
        import ("./NoOfPendingCasesByCategoryAgainstOfficer")
})
const NoOfPendingCasesBySubCategoryAgainstOfficer = MatxLoadable({
    loader: () =>
        import ("./NoOfPendingCasesBySubCategoryAgainstOfficer")
})
const NoOfComplainsReceivedByMainCategory = MatxLoadable({
    loader: () =>
        import ("./NoOfComplainsReceivedByMainCategory")
})
const NoOfComplainsReceivedBySubCategory = MatxLoadable({
    loader: () =>
        import ("./NoOfComplainsReceivedBySubCategory")
})

const ReportRoutes = [{
        path: "/report/allcases",
        component: AllCases,
        auth: authRoles.report_top
    },
    {
        path: "/report/callings",
        component: Callings,
        auth: authRoles.report_top
    },
    {
        path: "/report/officer/callings",
        component: CallingsOfficer,
        auth: authRoles.report
    },
    {
        path: "/report/recommendations",
        component: Recommendation,
        auth: authRoles.report_top
    },
    {
        path: "/report/officer/recommendations",
        component: RecommendationOfficer,
        auth: authRoles.report
    },
    {
        path: "/report/summons",
        component: Summon,
        auth: authRoles.report_top
    },
    {
        path: "/report/officer/summons",
        component: SummonOfficer,
        auth: authRoles.report
    },
    {
        path: "/report/summary",
        component: Summary,
        auth: authRoles.report_top
    },
    {
        path: "/report/status_of_cases_against_officers",
        component: StatusOfCasesAgainstOfficersReport,
        auth: authRoles.report_top
    },
    {
        path: "/report/no_of_cases_for_year_against_category",
        component: NoOfCasesForYearAgainstCategoryReport,
        auth: authRoles.report_top
    },
    {
        path: "/report/no_of_cases_for_year_against_subcategory",
        component: NoOfCasesForYearAgainstCategorySub,
        auth: authRoles.report_top
    },
    {
        path: "/report/no_of_pendingcases_bycategory_againstofficer",
        component: NoOfPendingCasesByCategoryAgainstOfficer,
        auth: authRoles.report_top
    },
    {
        path: "/report/no_of_pendingcases_by_subcategory_againstofficer",
        component: NoOfPendingCasesBySubCategoryAgainstOfficer,
        auth: authRoles.report_top
    },
    {
        path: "/report/no_of_complains_received_by_maincategory",
        component: NoOfComplainsReceivedByMainCategory,
        auth: authRoles.report_top
    },
    {
        path: "/report/no_of_complains_received_by_subcategory",
        component: NoOfComplainsReceivedBySubCategory,
        auth: authRoles.report_top
    },

];

export default ReportRoutes;