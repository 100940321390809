import React, { Component } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { SimpleCard, MatxProgressBar } from "matx";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { withStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';

import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import FocalPointService from './../../../services/FocalPointService';


const styles = theme => ({
    cssLabel: {
        color: 'red'
    },

    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `${theme.palette.primary.main} !important`,
        }
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'blue !important'
    },

});
class Focalpointdepartment extends Component {


    state = {
        snakebar: false,
        message: "",
        severity: "success",
        formdata: { name: "", code: "", email: "", address: "", contact_no: "", fax_no: "", subject_area: "", status: "" },

    };
    componentDidMount() {

    }


    handleChange = event => {
        event.persist();
        var data = this.state.formdata;

        data[event.target.name] = event.target.value;
        this.setState({
            formdata: data
        });

    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snakebar: false })

    };

    handleFormSubmit = event => {
        var data = this.state.formdata;

        if (data.code == "")
            data.code = "Not provided"

        if (data.contact_no == "")
            data.contact_no = 0

        if (data.subject_area == "")
            data.subject_area = "Not provided"

        FocalPointService.createFocalPoint(data).then(res => {
            if (res.status == 200) {
                this.setState({ message: res.data.message, snakebar: true, severity: "success" })
                setTimeout(() => {
                    window.location.reload(false);
                }, 3000);
            } else {
                this.setState({ message: "Can not create department", snakebar: true, severity: "error" })
            }
        })
            .catch((error) => {
                console.log("error", error);
            })

    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <SimpleCard title="Focal Point Department">


                    <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={3} >
                            <Grid item xs={12} sm={6}>
                                <TextValidator
                                    className="mb-24 w-100"
                                    variant="outlined"
                                    label={"Department Name"}
                                    onChange={this.handleChange}
                                    size='small'
                                    name="name"
                                    value={this.state.formdata.name}
                                    error
                                    helperText="Required Field "
                                    validators={["required"]}
                                    errorMessages={[
                                        "this field is required"
                                    ]}
                                />
                                <TextField
                                    className="mb-24 w-100"
                                    variant="outlined"
                                    label="Department Code"
                                    onChange={this.handleChange}
                                    size='small'
                                    name="code"
                                    value={this.state.formdata.code}
                                />
                                <TextValidator
                                    className="mb-24 w-100"
                                    variant="outlined"
                                    label="Postal Address"
                                    onChange={this.handleChange}
                                    size='small'
                                    name="address"
                                    value={this.state.formdata.address}
                                    error
                                    error
                                    helperText="Required Field "
                                    validators={["required"]}
                                    errorMessages={[
                                        "this field is required"
                                    ]}
                                />
                                <TextField
                                    className="mb-24 w-100"
                                    variant="outlined"
                                    label="Phone Number"
                                    onChange={this.handleChange}
                                    size='small'
                                    // type="number"
                                    name="contact_no"
                                    type="number"
                                    value={this.state.formdata.contact_no}

                                />
                            </Grid>


                            <Grid item xs={12} sm={6}>
                                <TextField
                                    className="mb-24 w-100"
                                    variant="outlined"
                                    label="Email"
                                    onChange={this.handleChange}
                                    size='small'
                                    type="email"
                                    name="email"
                                    value={this.state.formdata.email}

                                />
                                <TextField
                                    className="mb-24 w-100"
                                    variant="outlined"
                                    label="Fax Number"
                                    onChange={this.handleChange}
                                    size='small'
                                    name="fax_no"
                                    type="number"
                                    value={this.state.formdata.fax_no}
                                />
                                <TextField
                                    className="mb-24 w-100"
                                    variant="outlined"
                                    label="Subject Area"
                                    onChange={this.handleChange}
                                    size='small'
                                    name="subject_area"
                                    value={this.state.formdata.subject_area}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            type="submit"
                            startIcon={<SaveIcon />}
                        >
                            Save
                        </Button>

                    </ValidatorForm>
                </SimpleCard>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.snakebar} autoHideDuration={2500} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity={this.state.severity}>
                        {this.state.message}
                    </Alert>
                </Snackbar>

            </div>
        );
    };

}

Focalpointdepartment.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Focalpointdepartment);
