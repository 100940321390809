import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";
import Unit from "./Unit";

const unit = MatxLoadable({
  loader: () => import("./Unit")
})

const unitroute = [
  {
    path: "/datasetup/unit",
    component: Unit,
    auth: authRoles.datasetup
  }
];

export default unitroute;