import axios from 'axios';
import * as appconst from './../appconst';
import localStorageService from "./localStorageService";

class UnitService {

    getallunit = async () => {//Get All Unit 
      const token = await localStorageService.getItem("auth_user");
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token.token}`
        }
      };
        const a = new Promise((resolve, reject) => {
          axios.get(appconst.UNIT,headers)
            .then(res => {
              
              return resolve(res.data.Unit);
            })
            .catch((error) => {
              console.log("error", error);
            })
        });
        return await a;
      }

      createunit = async (data) => {//Create new unit
        const a = new Promise((resolve, reject) => {
          axios.post(appconst.UNIT , data).then(res => { 
              console.log(res)
              return resolve(res);
            })
            .catch((error) => {
              console.log("error", error);
            })
        });
        return await a;
      }

      getunitbyId = async (id) => {//Get unit by id
        const token = await localStorageService.getItem("auth_user");
        const headers = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token.token}`
          }
        };
        const a = new Promise((resolve, reject) => {
          axios.get(appconst.UNIT + "/" + id,headers)
            .then(res => {
              return resolve(res);
            })
            .catch((error) => {
              console.log("error", error);
            })
        });
        return await a;
      }
    
      editunit = async (id,data) => {//Edit unit by id
        const a = new Promise((resolve, reject) => {
          axios.put(appconst.UNIT + "/" + id, data).then(res => {
              return resolve(res);
            })
            .catch((error) => {
              console.log("error", error);
            })
        });
        return await a;
      }
    
      deleteunit = async (id) => {//Delete unit by id
        const a = new Promise((resolve, reject) => {
          axios.delete(appconst.UNIT + "/" + id).then(res => {
              return resolve(res);
            })
            .catch((error) => {
              console.log("error", error);
            })
        });
        return await a;
      }

      deactiveUnit = async (id) => {//Delete ReferalPoint
        const a = new Promise((resolve, reject) => {
          axios.put(appconst.UNIT + "/delete/" + id).then(res => {
              return resolve(res);
            })
            .catch((error) => {
              console.log("error", error);
            })
        });
        return await a;
      }

}
export default new UnitService();