import React, { Component } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { SimpleCard } from "matx";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';

import { withStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import OrganizationService from './../../../services/OrganizationService';
import Tooltip from '@material-ui/core/Tooltip';
const styles = theme => ({
    cssLabel: {
        color: 'red'
    },

    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `${theme.palette.primary.main} !important`,
        }
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'blue !important'
    },

});
class Organizationnewtable extends Component {
    state = {
        officeeditmodel: false,
        organizations: [],
        deleteconform: false,
        snakebar: false,
        message: "",
        severity: "success",
        id: '',
        formdata: { name: "", status: "" },

    };

    componentDidMount() {
        this.loadOrganization();
    }

    loadOrganization() {//Loading all regional offices 
        OrganizationService.getallorganization().then((data) => {
            this.setState({ organizations: data })
        });
    }


    handleDialogClose() {//For handle edit dialog
        this.setState({ officeeditmodel: false })
    }

    handleClose = (event, reason) => {//For handle alert visibility
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snakebar: false })
    };


    handleopen(data) {//For open edit selected organizations

        this.setState({
            id: data.id,
            formdata: { name: data.name, status: data.status } //edit this if not working
        });

        this.setState({ officeeditmodel: true })
    }


    handleChange = event => {//For Set edited form data
        event.persist();
        var data = this.state.formdata;


        data[event.target.name] = event.target.value;
        this.setState({
            formdata: data
        });
        console.log(data)

    };

    setdropdowndata(key, value) {// Set value of dropdown
        var data = this.state.formdata;
        if (value == null) {
            data[key] = "";
            this.setState({ formdata: data });
        } else {
            data[key] = value.title;
            this.setState({ formdata: data });
        }
    }


    handleFormSubmit = event => {//For submit edited reginal office
        let id = this.state.id;
        let data = this.state.formdata;

        OrganizationService.editOrganization(id, data).then(res => {
            if (res.status == 200) {
                this.setState({ message: res.data.message, snakebar: true, severity: "success", officeeditmodel: false })
                setTimeout(() => {
                        this.componentDidMount()
                    }, 2500);
            } else {
                this.setState({ message: "Can not edit Organization", snakebar: true, severity: "error" })
            }
        });
    }
    changeStatus = (data) => {//For delete selected category
        let id = data.id;
        if (data.status === "Active") {
            OrganizationService.deactiveOrganization(data.id).then(res => {
                if (res.status == 200) {
                    this.setState({ message: res.data.message, snakebar: true, severity: "success", officeeditmodel: false })
                    setTimeout(() => {
                        this.componentDidMount()
                    }, 2500);
                } else {
                    this.setState({ message: "Can not edit Organization", snakebar: true, severity: "error" })
                }
            });
        } else {
            //let id = this.state.id;
            //let data = this.state.formdata;
            data["status"] = "Active";
            OrganizationService.editOrganization(id, data).then(res => {
                if (res.status == 200) {
                    this.setState({ message: res.data.message, snakebar: true, severity: "success", officeeditmodel: false })
                    setTimeout(() => {
                        this.componentDidMount()
                    }, 2500);
                } else {
                    this.setState({ message: "Can not edit Organization", snakebar: true, severity: "error" })
                }
            });
        }
    }


    delete(id) {//For delete selected reginal office
        OrganizationService.deleteOrganization(id).then(res => {
            if (res.status == 200) {
                this.setState({ message: res.data.message, snakebar: true, severity: "success" })
                setTimeout(() => {
                        this.componentDidMount()
                    }, 2500);
            } else {
                this.setState({ message: "Can not edit Organization", snakebar: true, severity: "error" })
            }
        })

    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <SimpleCard title="Organization">
                    <TableContainer component={Paper}>
                        <Table style={{ minWidth: 500 }} stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Organization</TableCell>
                                    <TableCell align="center">Type</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.organizations.map((row) => (
                                    <TableRow key={row.id}>

                                        <TableCell align="center">{row.name}</TableCell>
                                        <TableCell align="center">{row.type}</TableCell>
                                        <TableCell align="center">{row.status}</TableCell>
                                        <TableCell align="center" style={{ minWidth: 100 }}>
                                            <Tooltip title="Edit" arrow placement="right">
                                                <IconButton aria-label="edit" onClick={() => { this.handleopen(row); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={row.status === "Active" ? "Delete" : "Recover"} arrow placement="right">
                                                <Switch
                                                    checked={row.status === "Active" ? true : false}
                                                    onClick={() => { this.changeStatus(row); }}
                                                    color="primary"
                                                    size="small"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </Tooltip>
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                    <Dialog fullWidth={true} onClose={() => { this.handleDialogClose(); }} aria-labelledby="customized-dialog-title" open={this.state.officeeditmodel}>
                        <div className="pb-30 px-30 w-90">
                            <DialogTitle id="customized-dialog-title">
                                Edit Organization
                            </DialogTitle>
                            <Grid item >
                                <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                                    <TextValidator
                                        className="mb-24 w-100"
                                        variant="outlined"
                                        label="Organization"
                                        onChange={this.handleChange}
                                        size='small'
                                        name="name"
                                        value={this.state.formdata.name}
                                        error
                                        helperText="Required Field "
                                        validators={["required"]}
                                        errorMessages={[
                                            "this field is required"
                                        ]}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        type="submit"
                                        startIcon={<SaveIcon />}
                                    >
                                        Save
                                    </Button>


                                </ValidatorForm>
                            </Grid>
                        </div>
                    </Dialog>

                </SimpleCard>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.snakebar} autoHideDuration={1000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity={this.state.severity}>
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        );
    };

}

Organizationnewtable.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Organizationnewtable);
