import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const Departments = MatxLoadable({
  loader: () => import("./Departments")
})

const DepartmentsRoutes = [
  {
    path: "/datasetup/departments",
    component: Departments,
    auth: authRoles.datasetup
  }
];

export default DepartmentsRoutes;
