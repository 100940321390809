import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const Users = MatxLoadable({
    loader: () =>
        import ("./Users")
})
const AddUsers = MatxLoadable({
    loader: () =>
        import ("./AddUsers")
})
const EditUser = MatxLoadable({
    loader: () =>
        import ("./EditUser")
})
const UserProfile = MatxLoadable({
    loader: () =>
        import ("./UserProfile")
})

const UsersRoutes = [{
        path: "/datasetup/users",
        component: Users,
        auth: authRoles.datasetup
    },
    {
        path: "/datasetup/createusers",
        component: AddUsers,
        auth: authRoles.datasetup
    },
    {
        path: "/datasetup/editusers/:id",
        component: EditUser,
        auth: authRoles.datasetup
    },
    {
        path: "/datasetup/userprofile",
        component: UserProfile,
        auth: authRoles.all
    }
];

export default UsersRoutes;