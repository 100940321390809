import React, { Component, Fragment } from "react";
import {
  Grid,
  Card
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import Organizationlist from "./Organizationtlist";
import Button from '@material-ui/core/Button';
import Organizationnewtable from "./Organizationnewtable";

class Organization extends Component {
  state = {};

  render() {
    let { theme } = this.props;

    return (
      <Fragment>
         
        <div className="pb-10 pt-30 px-30 ">
          <Organizationnewtable/>
        </div>
        <div className="pb-10 pt-30 px-30 ">
          <Organizationlist/>
        </div>
      </Fragment>

   
    );
  }
}



export default withStyles({}, { withTheme: true })(Organization);
