import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const Sumotto = MatxLoadable({
    loader: () =>
        import ("./CreateSumotto")
})


const SumottoRoutes = [{
    path: "/sumoto",
    component: Sumotto,
    auth: authRoles.sumotto
}];

export default SumottoRoutes;