import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppContext from "app/appContext";

class AuthGuard extends Component {
  constructor(props, context) {
    super(props);
    let { routes } = context;

    this.state = {
      authenticated: false,
      routes
    };
  }

  componentDidMount() {
    if (!this.state.authenticated) {
      this.redirectRoute(this.props);
    }
  }

  componentDidUpdate() {
    if (!this.state.authenticated) {
      this.redirectRoute(this.props);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.authenticated !== this.state.authenticated;
  }

  static getDerivedStateFromProps(props, state) {
    const { location, user } = props;
    const { pathname } = location;
    const matched = state.routes.find(r => r.path === pathname);

    //   const hasCrossReferencePermission = hasAccessTo(
    //     [Permissions.CROSS_REFERENCE, Permissions.EDIT_CROSS_REFERENCE],
    //     user,
    // );
    let authenticated = false;
    if(matched!==undefined){
    if (matched.auth !== undefined) {
      if (user.roles !== undefined) {
        const userpermission = user.roles.map(roles => roles).flat();
      
        matched.auth.forEach(permission => {
          if (userpermission.find(p => p === permission)) {
            authenticated = true;
            //console.log(access, "pp")
          }
        })

      }
    } else {
      authenticated = false;
    }
  }
    if (!(matched && matched.auth && matched.auth.length)) {
      authenticated = true;
    }

    // const authenticated =
    //   matched && matched.auth && matched.auth.length
    //     ? matched.auth.includes(user.role)
    //     : true;
    //     console.log('sds', authenticated)
    //  const authenticated =true;

    return {
      authenticated
    };
  }

  redirectRoute(props) {
    const { location, history } = props;
    const { pathname } = location;

    history.push({
      pathname: "/session/404",
      state: { redirectUrl: pathname }
    });
  }

  render() {
    let { children } = this.props;
    const { authenticated } = this.state;
    return authenticated ? <Fragment>{children}</Fragment> : null;
  }
}

AuthGuard.contextType = AppContext;

const mapStateToProps = state => ({
  user: state.user
});

export default withRouter(connect(mapStateToProps)(AuthGuard));
