import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const ViewCases = MatxLoadable({
  loader: () => import("./ViewCases/ViewCases")
})
const MarkedCan = MatxLoadable({
  loader: () => import("./MarkedCan/MarkedCan")
})
const MarkedCannot = MatxLoadable({
  loader: () => import("./MarkedCannot/MarkedCannot")
})

const FocalPoint = MatxLoadable({
  loader: () => import("./RefFocalpoint/Focalpoint")
})

const Summon = MatxLoadable({
  loader: () => import("./SummonView/Summon")
})
const SummonView = MatxLoadable({
  loader: () => import("./SummonView/SummonView")
})

const DirectorAllCaseRoutes = [
  {
    path: "/director/allcases",
    component: ViewCases,
    auth: authRoles.directorAllCases
  },
  {
    path: "/director/markedcancases",
    component: MarkedCan,
    auth: authRoles.directorMarkedCan
  },
  {
    path: "/director/markedcannotcases",
    component: MarkedCannot,
    auth: authRoles.directorMarkedCannot
  },

  {
    path: "/director/focalpointcases",
    component: FocalPoint,
    auth: authRoles.directorRefFocalpoint
  },
  {
    path: "/SummonView/Director",
    component: Summon,
    auth: authRoles.directorAllCases
  },
  {
    path: "/SummonView/Single/:id",
    component: SummonView,
    auth: authRoles.directorAllCases
  },
];

export default DirectorAllCaseRoutes;