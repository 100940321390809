import axios from 'axios';
import * as appconst from './../appconst';
import localStorageService from "./localStorageService";

class  ReferalPointService {
  ls = window.localStorage

  getallReferalPoint = async () => {//Get All ReferalPoint
    const token = await localStorageService.getItem("auth_user");
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.token}`
      }
    };

    const a = new Promise((resolve, reject) => {
      axios.get(appconst.REFERAL_POINTS,headers)
        .then(res => {
          
          return resolve(res.data.CannotReferalPoints);
        })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  getReferalPointbyId = async (id) => {//Get ReferalPoint by id
    const token = await localStorageService.getItem("auth_user");
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.token}`
      }
    };
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.REFERAL_POINTS + "/" + id,headers)
        .then(res => {
          return resolve(res);
        })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  editReferalPoint = async (id,data) => {//Edit ReferalPoint by id
    const a = new Promise((resolve, reject) => {
      axios.put(appconst.REFERAL_POINTS + "/" + id, data).then(res => {
          return resolve(res);
        })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  deleteReferalPoint = async (id) => {//Delete ReferalPoint by id
    const a = new Promise((resolve, reject) => {
      axios.delete(appconst.REFERAL_POINTS + "/" + id).then(res => {
          return resolve(res);
        })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  deactiveReferalPoint = async (id) => {//Delete ReferalPoint
    const a = new Promise((resolve, reject) => {
      axios.put(appconst.REFERAL_POINTS + "/delete/" + id).then(res => {
          return resolve(res);
        })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  createReferalPoint= async (data) => {//Create new ReferalPoint
    const a = new Promise((resolve, reject) => {
      axios.post(appconst.REFERAL_POINTS , data).then(res => {
          return resolve(res);
        })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

}

export default new ReferalPointService();