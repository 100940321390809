import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const Route = MatxLoadable({
  loader: () => import("./Route")
})

const RouteRoutes = [
  {
    path: "/datasetup/route",
    component: Route,
    auth: authRoles.datasetup
  }
];

export default RouteRoutes;
