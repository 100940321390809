import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles"
import Referalpoints from "./Referalpoints"; 

const referalpoints = MatxLoadable({
  loader: () => import("./Referalpoints")
})

const referalpointsRoute = [
  {
    path: "/datasetup/referalpoints",
    component: Referalpoints,
    auth: authRoles.datasetup
  }
];

export default referalpointsRoute ;