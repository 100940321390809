import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const OldCaseslist = MatxLoadable({
  loader: () => import("./OldCaseslist")
})
const OldCanCaseSingle = MatxLoadable({
    loader: () => import("./OldCanCaseSingle")
  })
  const OldCannotCaseSingle = MatxLoadable({
    loader: () => import("./OldCannotCaseSingle")
  })



const OldCasesRoutes = [
  {
    path: "/oldCasesall",
    component: OldCaseslist,
    auth: authRoles.temporrycases
  },
  {
    path: "/OldCanCaseSingle/:id",
    component: OldCanCaseSingle,
    auth: authRoles.temporrycases
  },
  {
    path: "/OldCannotCaseSingle/:id",
    component: OldCannotCaseSingle,
    auth: authRoles.temporrycases
  }
 
];



export default OldCasesRoutes;
