import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const designation = MatxLoadable({
  loader: () => import("./Designation")
})

const designationroute = [
  {
    path: "/datasetup/designation",
    component: designation,
    auth: authRoles.datasetup
  }
];

export default designationroute;