import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const Cannot_reason = MatxLoadable({
  loader: () => import("./Cannot_reason")
})

const Cannot_reasonRoutes = [
  {
    path: "/datasetup/cannot_reason",
    component: Cannot_reason,
    auth: authRoles.datasetup
  }
];

export default Cannot_reasonRoutes;
