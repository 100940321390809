import { MatxLoadable } from "matx";
import { authRoles } from "../../../../auth/authRoles";

// const AcceptCase = MatxLoadable({
//   loader: () => import("./AcceptCase")
// })

const AcceptCaseRoutes = [
  // {
  //   path: "/AcceptCase/Officer/:id",
  //   component: AcceptCase,
  //   auth: authRoles.OfficerAcceptCase
  // }
];

export default AcceptCaseRoutes;