import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const Complaint = MatxLoadable({
  loader: () => import("./Complaint")
})

const ComplaintRoutes = [
  {
    path: "/complaint",
    component: Complaint,
    auth: authRoles.createComplaint
  }
];

export default ComplaintRoutes;
