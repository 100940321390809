import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles"


const articles = MatxLoadable({
  loader: () => import("./Articles")
})

const Articlesroute = [
  {
    path: "/datasetup/articles",
    component: articles,
    auth: authRoles.datasetup
  }
];

export default Articlesroute;