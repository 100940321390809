import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const timeslot = MatxLoadable({
  loader: () => import("./TimeSlot")
})

const timeslotroute = [
  {
    path: "/datasetup/timeslots",
    component: timeslot,
    auth: authRoles.datasetup
  }
];

export default timeslotroute;