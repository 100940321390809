import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const CaseRegister = MatxLoadable({
  loader: () => import("./CaseRegister")
})

const CaseRegisterRoutes = [
  {
    path: "/CaseRegister",
    component: CaseRegister,
    auth: authRoles.CaseRegister
  }
];

export default CaseRegisterRoutes;