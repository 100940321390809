import { MatxLoadable } from "matx";
import { authRoles } from "../../../../auth/authRoles";


const All = MatxLoadable({
    loader: () =>
        import ("./AllTable")
})

const OpinionRoutes = [{
    path: "/opinions",
    component: All,
    auth: authRoles.CaseRegister
}, ];

export default OpinionRoutes;