import axios from 'axios';
import * as appconst from '../appconst';
import localStorageService from "./localStorageService";

class  FocalPointOfficerService { 
  ls = window.localStorage

  getallFocalPointOfficer = async () => {//Get All FocalPointOfficer
    const token = await localStorageService.getItem("auth_user");
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.token}`
      }
    };
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.FOCAL_POINT_OFFICERS,headers)
        .then(res => {
           
          return resolve(res.data.FocalPointOfficer);
        })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  getFocalPointOfficerbyId = async (id) => {//Get FocalPointOfficer by id
    const token = await localStorageService.getItem("auth_user");
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.token}`
      }
    };
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.FOCAL_POINT_OFFICERS + "/" + id,headers)
        .then(res => {
          return resolve(res);
        })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  editFocalPointOfficer = async (id,data) => {//Edit FocalPointOfficer by id
    const a = new Promise((resolve, reject) => {
      axios.put(appconst.FOCAL_POINT_OFFICERS + "/" + id, data).then(res => {
          return resolve(res);
        })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  deleteFocalPointOfficer = async (id) => {//Delete FocalPointOfficer by id
    const a = new Promise((resolve, reject) => {
      axios.delete(appconst.FOCAL_POINT_OFFICERS + "/" + id).then(res => {
          return resolve(res);
        })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  deactiveFocalPointOfficer = async (id) => {//Delete FocalPointOfficer
    const a = new Promise((resolve, reject) => {
      axios.put(appconst.FOCAL_POINT_OFFICERS + "/delete/" + id).then(res => {
          return resolve(res);
        })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  createFocalPointOfficer = async (data) => {//Create new FocalPointOfficer
    const a = new Promise((resolve, reject) => {
      axios.post(appconst.FOCAL_POINT_OFFICERS , data).then(res => {
          return resolve(res);
        })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

}

export default new FocalPointOfficerService();