export const district = [
    {value:'Jaffna', title: 'Jaffna' , province:'Northern'},
    {value:'Kilinochchi', title: 'Kilinochchi',province:'Northern' },
    {value:'Mannar', title: 'Mannar',province:'Northern' },
    {value:'Mullaitivu', title: 'Mullaitivu' ,province:'Northern'},
    {value:'Vavuniya', title: 'Vavuniya',province:'Northern' },
    {value:'Puttalam', title: 'Puttalam' ,province:'North Western'},
    {value:'Kurunegala', title: 'Kurunegala' ,province:'North Western'},
    {value:'Gampaha', title: 'Gampaha' ,province:'Western'},
    {value:'Colombo', title: 'Colombo',province:'Western' },
    {value:'Kalutara', title: 'Kalutara',province:'Western' },
    {value:'Anuradhapura', title: 'Anuradhapura',province:'North Central' },
    {value:'Polonnaruwa',title: 'Polonnaruwa',province:'North Central' },
    {value:'Matale', title: 'Matale',province:'Central' },
    {value:'Kandy', title: 'Kandy' ,province:'Central'},
    {value:'Nuwara Eliya', title: 'Nuwara Eliya',province:'Central' },
    {value:'Kegalle', title: 'Kegalle' ,province:'Sabaragamuwa'},
    {value:'Ratnapura', title: 'Ratnapura',province:'Sabaragamuwa' },
    {value:'Trincomalee', title: 'Trincomalee' ,province:'Eastern'},
    {value:'Batticaloa', title: 'Batticaloa',province:'Eastern' },
    {value:'Ampara', title: 'Ampara',province:'Eastern' },
    {value:'Badulla', title: 'Badulla' ,province:'Uva'},
    {value:'Monaragala',title: 'Monaragala' ,province:'Uva'},
    {value:'Hambantota', title: 'Hambantota' ,province:'Southern'},
    {value:'Matara', title: 'Matara' ,province:'Southern'},
    {value:'Galle', title: 'Galle' ,province:'Southern'}
];
export const province = [
    { value:'Northern', title: 'Northern' },
    { value:'North Central', title: 'North Central' },
    { value:'Eastern', title: 'Eastern' },
    { value:'North Western', title: 'North Western' },
    { value:'Central', title: 'Central' },
    { value:'Uva', title: 'Uva' },
    { value:'Western', title: 'Western' },
    { value:'Sabaragamuwa', title: 'Sabaragamuwa' },
    { value:'Southern', title: 'Southern' }
];

export const organizations = [
    {value:'Police', title:'Police'},
    {value:'Army', title:'Army'},
    {value:'Navy', title:'Navy'},
    {value:'CEB', title:'CEB'},
    {value:'RDA', title:'RDA'},
];
export const organization_types = [
    {value:'Case', title:'Case'},
    {value:'Complain Form', title:'Complain Form'},
    {value:'Complainant Employer', title:'Complainant Employer'},
];
export const designation = [];

export const unit = [

];
export const type = [
    { title: 'All' },
    { title: 'walkin' },
    { title: 'post' },
    { title: 'hotline' },
    { title: 'fax' },
    { title: 'mail' },
    { title: 'web' },
    { title: 'Suo Motu' }
];

export const sumotto_type = [
    { title: 'Television' },
    { title: 'Radio' },
    { title: 'Newspaper' },
    { title: 'Social Media' },
    { title: 'Other' },

];

export const can_status = [
    { 
        title: 'All' ,
        value: 'All' 
    },
    { 
        title: 'Not Accepted',
        value: 'Pending'
     },
    { 
        title: 'Accept' ,
        value: 'Accept' 
    },
    { 
        title: 'Reject' ,
        value: 'Reject' 
    }
];

export const letter_status = [
    { title: 'All',value: 'All' },
    { title: 'Sent' ,value: 'Sent'},
    { title: 'Not Sent' ,value: 'Pending'}

];

export const ma_cancasefilter = [
    { title: 'Case Number' },
    { title: 'Petitioner Name' },
    { title: 'Victim Name' },
    { title: 'Respondent Name ' },
];

export const organization_type = [
    { title: 'Government' },
    { title: 'Semi' },
    { title: 'Private' },
];

export const language = [
    { title: 'English' },
    { title: 'Sinhala' },
    { title: 'Tamil' }
];

export const letter_format = [
    { title: 'Advise To Refer' },
    { title: 'Advise To Refer - Ombudsman' },
    { title: 'Not within Mandate' },
    { title: 'No Fundamental Rights' },
    { title: 'Time Bar' },

];

export const referralpoint_letter = [

    { title: 'Referral Point - Sinhala' },
    { title: 'Referral Point - Tamil' }
];
export const letter_formatall = [
    { title: 'Advise To Refer' },
    { title: 'Advise To Refer - Ombudsman' },
    { title: 'Not within Mandate' },
    { title: 'No Fundamental Rights' },
    { title: 'Time Bar' },
    { title: 'Referral Point - Sinhala' },
    { title: 'Referral Point - Tamil' }

];

export const calling_letters = [
    { title: 'Report Calling' },
    { title: 'Petitioner Calling' },
    { title: 'MLEF Calling' },
    { title: 'Medical Report Calling' },
    { title: 'Other' }
]


export const report_types = [
    { title: 'Complainer Written Submission' },
    { title: 'Respondent Written Submission' },
    { title: 'Observation Report' },
    { title: 'Eye Witness Report' },
    { title: 'Petitioner Calling' },
    { title: 'Report Calling' }
]

export const report_calling_status = [
    { title: 'Pending' },
    { title: 'Received' },
    { title: 'Delayed' },
]


export const calling_for = [
    { title: 'Complainer' },
    { title: 'Victim' },
    { title: 'Respondent' },
    { title: 'Medical Officer' },
    { title: 'Prison Superintendent' }
]

export const callingResponsetype = [
    { title: "Response" },
    { title: "Statement" }
]
export const oldcase = [
    { title: 'Can' },
    { title: 'Cannot' }
];


export const timeSlots = [
    { title: "Morning Slot 1" },
    { title: "Morning Slot 2" },
    { title: "Evening Slot 1" },
    { title: "Evening Slot 2" },
]

export const summon_send_type = [
    { title: 'Fax' },
    { title: 'Post' },
    { title: 'Email' },
    { title: 'Hand' }
];

export const summon_cancle_reasons = [
    { title: 'Respondent Request' },
    { title: 'Petitioner Request' },
    { title: 'Commissions decision to Cancel' },
    { title: 'Officers Request' },
    { title: 'Case been settled' },
    { title: 'Relief granted' },
    { title: 'Other' }
];


export const summon_results = [
    { title: "Absent" },
    { title: "Re-summon" },
    { title: "Relief Granted" },
    { title: "Settlement" },
    { title: "Other" },
]
export const rec_types = [
    { title: 'Draft Recommendation' },
    { title: 'Draft No Violation' },
    { title: 'Draft Inquiry Report' },
    { title: 'Recommendation- Non Imp Unit' }
];

export const report_type = [
    { title: 'Inquiry Report' },
    { title: 'No Violation Report' },
]

export const send_to = [
    { title: 'Non Implementation Unit' },
    { title: 'Record Room' },
    // { title: 'Other' }
]

export const reason = [
    { title: 'Withdrawal on Complainer Request' },
    { title: 'Withdrawal on Victim Request' },
    { title: 'Respondent Report Calling' },
    { title: 'No FR Violation' },
    { title: 'Not within Mandate' },
    { title: 'Court Case' },
    { title: 'Relief Granted' },
    { title: 'Not Interest' },
    { title: 'Court Case' },
    { title: 'SCRF' },
    { title: 'Settlement from Inquiry' },
    { title: 'After Recommendation' },
    { title: 'After Implementation' },
    { title: 'Other' }
]

export const summon_status = [
    { title: 'Accept' },
    { title: 'Pending' },
    { title: 'Rejected' },
]

export const summon_response = [
    { title: 'No Change' },
    { title: 'Inquiry Cancellation ' }
]


export const case_types = [
    { title: "Can" },
    { title: "Can Not" },
    { title: "Focal Point" },
    { title: "Relief Granted" },
    { title: "Closed" },
    { title: "Settled" },
    { title: "Advice to Reffer" },
    { title: "Not Accept" },
]

export const case_sourse = [
    { title: 'walkin' },
    { title: 'post' },
    { title: 'hotline' },
    { title: 'fax' },
    { title: 'mail' },
    { title: 'web' },
    { title: "Suo Motu" },
]

export const case_status = [
    { title: "Closed" },
    { title: "Pending" }
]

export const genders = [
    { title: "Female" },
    { title: "Male" },
    { title: "Other" },
    { title: "Not Mention" },
]

export const recommendtations_types = [
    { title: "Final" },
    { title: "Draft" },
    { title: "Issue" }
]

export const all_case_report_column = [
    { title: "Case Number", selected: true, value: "case_number", type: 'table' },
    { title: "Reported Date", selected: false, value: "rep_date", type: 'table' },
    { title: "Type", selected: false, value: "type", type: 'table' },
    { title: "Cannot Reason", selected: false, value: "cannot_reason", type: 'table' },
    { title: "Focal Point", selected: false, value: "focal_point", type: 'table' },
    { title: "Unit", selected: false, value: "unit", type: 'table' },
    { title: "Received Source", selected: false, value: "sourse", type: 'table' },
    { title: "Language", selected: false, value: "langauge", type: 'table' },
    { title: "Category ", selected: true, value: "category", type: 'table' },
    { title: "Subcategory", selected: true, value: "sub_category", type: 'table' },
    { title: 'HO/Regional Office', selected: false, value: "office", type: 'table' },
    { title: 'Duty Officer', selected: true, value: "duty_officer", type: 'table' },
    { title: 'Inquiry Officer', selected: true, value: "legal_officer", type: 'table' },
    { title: "Status", selected: false, value: "status", type: 'table' },

    { title: "Complainer Name", selected: true, value: "c_name", type: 'complainer' },
    { title: "Complainer's Organization", selected: false, value: "c_organization", type: 'complainer' },
    { title: "Complainer's Gender", selected: false, value: "c_gender", type: 'complainer' },
    { title: "Complainer's Age", selected: false, value: "c_age", type: 'complainer' },
    { title: "Complainer's Address", selected: false, value: "c_address", type: 'complainer' },
    { title: "Complainer's Contact Number", selected: false, value: "c_contac", type: 'complainer' },
    { title: "Complainer's Email", selected: false, value: "c_email", type: 'complainer' },
    { title: "Complainer's NIC Number", selected: false, value: "c_nic", type: 'complainer' },
    { title: "Complainer's District", selected: false, value: "c_district", type: 'complainer' },
    { title: "Victim Name", selected: true, value: "v_name", type: 'victim' },
    { title: "Victim's Organization", selected: false, value: "v_organization", type: 'victim' },
    { title: "Victim's Gender", selected: false, value: "v_gender", type: 'victim' },
    { title: "Victim's Age", selected: false, value: "v_age", type: 'victim' },
    { title: "Victim's Address", selected: false, value: "v_address", type: 'victim' },
    { title: "Victim's Contact Number", selected: false, value: "v_contact", type: 'victim' },
    { title: "Victim's Email", selected: false, value: "v_email", type: 'victim' },
    { title: "Victim's NIC Number", selected: false, value: "v_nic", type: 'victim' },
    { title: "Victim's District", selected: false, value: "v_sistrict", type: 'victim' },
    { title: "Respondent Name", selected: true, value: "r_name", type: 'respondent' },
    { title: "Respondent's Organization", selected: false, value: "r_organization", type: 'respondent' },
    { title: "Respondent's Organization Type", selected: false, value: "r_organization_type", type: 'respondent' },
    { title: "Respondent's Designation", selected: false, value: "r_designation", type: 'respondent' },
    { title: "Respondent's Gender", selected: false, value: "r_gender", type: 'respondent' },
    { title: "Respondent's Address", selected: false, value: "r_address", type: 'respondent' },
    { title: "Respondent's Contact Number", selected: false, value: "r_contact", type: 'respondent' },
    { title: "Respondent's Email", selected: false, value: "r_email", type: 'respondent' },
    { title: "Respondent's NIC Number", selected: false, value: "r_nic", type: 'respondent' },
    { title: "Respondent's District", selected: false, value: "r_district", type: 'respondent' },
    { title: "Respondent's Province", selected: false, value: "r_-province", type: 'respondent' },
    { title: "Other Name", selected: false, value: "o_name", type: 'other' },
    { title: "Other's Organization", selected: false, value: "o_organization_name", type: 'other' },
    { title: "Other's Organization Type", selected: false, value: "o_organization_type", type: 'other' },
    { title: "Other's Designation", selected: false, value: "o_designation", type: 'other' },
    { title: "Other's Gender", selected: false, value: "o_gender", type: 'other' },
    { title: "Other's Address", selected: false, value: "o_address", type: 'other' },
    { title: "Other's Contact Number", selected: false, value: "o_contact", type: 'other' },
    { title: "Other's Email", selected: false, value: "o_email", type: 'other' },
    { title: "Other's NIC Number", selected: false, value: "o_nic", type: 'other' },
    { title: "Other's District", selected: false, value: "o_district", type: 'other' },
    { title: "Other's Province", selected: false, value: "o_province", type: 'other' },
    { title: 'Reason', selected: true, value: "case_reason", type: 'table' },
    { title: 'Action', selected: true, value: "action", type: 'table' },
]


export const schedule_types = [
    { value: "HO", title: "Hotline" },
    { value: "PH", title: "Public Hearing Day" },
    { value: "VI", title: "Visit" },
    { value: "OL", title: "Officer's Leave Day" },
    { value: "RR", title: "Rapid Response" },
]

export const route_stop_types = [
    { title: "Police Station" },
    { title: "Prison" },
    { title: "Children's Home" },
    { title: "Elder's Home" },
    { title: "Hospital" },
    { title: "Detention Center" },
    { title: "Mental Hospital" },
]

export const visit_form_types = [
    { title: "Check List" },
    { title: "Instructions" },
    { title: "Questionnaire" }
]

export const visit_status = [
    { title: "Completed" },
    { title: "Pending" },
]

export const schedule_status = [
    { title: "New" },
    { title: "Approved" },
]


export const appeal_status = [
    { title: "Closed" },
    { title: "Pending" }
]


export const record_room_appeal_status = [
    { title: "New" },
    { title: "Pending" },
    { title: "Decided" }
]


export const record_room_appeal_next_steps = [
    { title: "Closing the Case" },
    { title: "Re-Open with Record Room" },
    { title: "Re-Open and Assign an Officer" },
    { title: "Other" }
]

export const none_imp_person_types = [
    { title: 'Complainer' },
    { title: 'Victim' },
    { title: 'Respondent' },
    { title: "Others" },
]


export const none_imp_backreport_status = [
    { title: "New" },
    { title: "Received" },
    { title: "Not Received" },
    { title: "Date extension Request" },
    { title: "Issue to another party" },
    { title: "Objection" },
]

export const none_imp_backreport_change_status = [
    { title: "Received" },
    { title: "Not Received" },
    { title: "Date extension Request" },
    { title: "Issue to another party" },
    { title: "Objection" },
]



export const general_appeal_status = [
    { title: 'Mark Decision' },
    { title: 'Forward to Another Party' },
];

export const summary_type = [
    { title: 'Type' },
    { title: 'Source' },
    { title: 'Regional Office' },
    { title: 'Duty Officer' },
    { title: 'Assign Officer' },
    { title: 'Unit' },
    { title: 'Category' },
    { title: 'Subcategory' },
    { title: 'Language' },
    { title: 'Organization' },
    { title: 'District' },
    { title: 'Provincial' },
    { title: 'Gender' },
];

export const summary_case_type = [
    { title: "Can Case" },
    { title: "Cannot Case" },
    // { title: "Closed" },
    { title: "Focal Point" },
    { title: "Not Accept" },
    { title: "Relief Granted" },
    { title: "Settled" },
    { title: "Advice to Reffer" }
];

export const case_status_new = [
    { title: "New", value: "New" },
    { title: "Temporary case", value: "Pending" },
    { title: "Assigned", value: "Assigned" },
    { title: "Reffered", value: "Reffered" },
    { title: "Closed", value: "Closed" },
]