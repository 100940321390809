import axios from "axios";
import localStorageService from "./localStorageService";
import * as appconst from './../appconst';
export const LOGIN_ERROR = "LOGIN_ERROR";

class JwtAuthService {

    // Dummy user object just for the demo
    /* user = {
       userId: "1",
       role: 'ADMIN',
       displayName: "Ishara Roshan",
       email: "roshan@loonslab.com",
       photoURL: "/assets/images/face-6.png",
       age: 25,
       token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh"
     };*/
    user = {};
    token = "";


    // You need to send http request with email and passsword to your server in this method
    // Your server will return user object & a Token
    // User should have role property
    // You can define roles in app/auth/authRoles.js


    loginWithEmailAndPassword = async(email, password) => {

        // axios.defaults.port = 8080;


        /*
         fetch("http://localhost:8080/auth/realms/HRCRealm/protocol/openid-connect/token", {
           method: 'POST',
           //mode: 'no-cors',
           headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/x-www-form-urlencoded',
           },
           body: 'grant_type=password&client_id=client2&username=admin&password=admin'
         }) .then(response => console.log(response))
         .catch(error => console.log(error))
         */

        return new Promise((resolve, reject) => {


            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            }

            axios.post(appconst.LOGIN_URL, `grant_type=password&client_id=client2&username=${email}&password=${password}`, {
                    headers: headers
                })
                .then((response) => {
                    var jwtDecode = require('jwt-decode');
                    var userdata = jwtDecode(response.data.access_token);
                    var user = {
                        userId: userdata.sub,
                        role: userdata.realm_access.roles[0],
                        roles: userdata.realm_access.roles,
                        //role:"Super Admin",
                        displayName: userdata.name,
                        email: userdata.email,
                        photoURL: "/assets/images/logonew.png",
                        age: 25,
                        token: response.data.access_token,
                        refresh_token: response.data.refresh_token
                    };
                    resolve(user);
                })
                .catch((error) => {
                    // console.log("error", error)
                    var data = {
                        Error: "Invalid user credentials",
                    }
                    resolve(data);
                })



        }).then(data => {
            // Login successful
            // Save token

            this.setSession(data.token);
            // Set user
            this.setUser(data);
            return data;
        });

    };

    loginWithRefreshToken = async() => {
        const refresh_token = await localStorageService.getItem("refresh_token");
        console.log("user refreshing", refresh_token)
        return new Promise((resolve, reject) => {


            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            }

            axios.post(appconst.REFRESH_TOKEN, `grant_type=refresh_token&client_id=client2&refresh_token=${refresh_token}`, {
                    headers: headers
                })
                .then((response) => {
                    var jwtDecode = require('jwt-decode');
                    var userdata = jwtDecode(response.data.access_token);
                    var user = {
                        userId: userdata.sub,
                        role: userdata.realm_access.roles[0],
                        roles: userdata.realm_access.roles,
                        //role:"Super Admin",
                        displayName: userdata.name,
                        email: userdata.email,
                        photoURL: "/assets/images/logonew.png",
                        age: 25,
                        token: response.data.access_token,
                        refresh_token: response.data.refresh_token
                    };
                    resolve(user);
                })
                .catch((error) => {
                    // console.log("error", error)
                    var data = {
                        Error: "Invalid user credentials",
                    }
                    resolve(data);
                })



        }).then(data => {
            // Login successful
            // Save token

            this.setSession(data.token);
            // Set user
            this.setUser(data);
            return data;
        });

    };


    loginWithRefreshToken = async() => {
        const refresh_token = await localStorageService.getItem("refresh_token");
        console.log("user refreshing", refresh_token)
        return new Promise((resolve, reject) => {


            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            }

            axios.post(appconst.REFRESH_TOKEN, `grant_type=refresh_token&client_id=client2&refresh_token=${refresh_token}`, {
                    headers: headers
                })
                .then((response) => {
                    var jwtDecode = require('jwt-decode');
                    var userdata = jwtDecode(response.data.access_token);
                    var user = {
                        userId: userdata.sub,
                        role: userdata.realm_access.roles[0],
                        roles: userdata.realm_access.roles,
                        //role:"Super Admin",
                        displayName: userdata.name,
                        email: userdata.email,
                        photoURL: "/assets/images/logonew.png",
                        age: 25,
                        token: response.data.access_token,
                        refresh_token: response.data.refresh_token
                    };
                    resolve(user);
                })
                .catch((error) => {
                    // console.log("error", error)
                    var data = {
                        Error: "Invalid user credentials",
                    }
                    resolve(data);
                })



        }).then(data => {
            // Login successful
            // Save token

            this.setSession(data.token);
            // Set user
            this.setUser(data);
            return data;
        });

    };

    // You need to send http requst with existing token to your server to check token is valid
    // This method is being used when user logged in & app is reloaded
    loginWithToken = () => {

        return new Promise((resolve, reject) => {
            this.user = localStorageService.getItem("auth_user");
            this.token = localStorageService.getItem("token");

            const headers = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.user.token}`
                },
            };
            fetch(appconst.USERINFOR_URL, headers)
                .then(response => response.json())
                .then(data => {

                    if (data.hasOwnProperty('error')) {
                        console.log("invalid")
                        resolve(data);
                    } else {
                        resolve(this.user);
                    }
                }).catch(function() {
                    var err = { error: "server error" }
                    resolve(err);
                });;


        }).then(data => {
            // Token is valid
            this.setSession(data.token);
            this.setUser(data);
            return data;
        });
    };

    logout = () => {
        this.setSession(null);
        this.removeUser();
    }

    // Set token to all http request header, so you don't need to attach everytime
    setSession = token => {
        if (token) {

            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            localStorage.setItem("jwt_token", token);
        } else {
            localStorage.removeItem("jwt_token");
            delete axios.defaults.headers.common["Authorization"];
        }
    };

    // Save user to localstorage
    setUser = (user) => {
        localStorageService.setItem("auth_user", user);
        if (!user.hasOwnProperty('error')) {
            localStorageService.setItem("refresh_token", user.refresh_token);
        }
    }


    // Remove user from localstorage
    removeUser = () => {
        localStorage.removeItem("auth_user");
    }
}

export default new JwtAuthService();