import { MatxLoadable } from "matx";
import { authRoles } from "../../../../auth/authRoles";

const CanCannotProcess = MatxLoadable({
  loader: () => import("./CanCannotProcess")
})

const CanCannotProcessRoutes = [
  {
    path: "/CanCannotProcess/:id",
    component: CanCannotProcess,
    auth: authRoles.directorCanCannotprocess
  }
];

export default CanCannotProcessRoutes;