import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const RecordRoomAllCases = MatxLoadable({
    loader: () =>
        import ("./Cases/All/TabBar")
})

const RecordRoomAllAppeals = MatxLoadable({
    loader: () =>
        import ("./Appeal/All/TabBar")
})



// const RecordRoomNewAppeals = MatxLoadable({
//     loader: () =>
//         import ("./Appeal/New/TabBar")
// })

const RecordRoomNewAppeals = MatxLoadable({
    loader: () =>
        import ("./Appeal/New/TabBar")
})

const RecordRoomNewAppealsCase = MatxLoadable({
    loader: () =>
        import ("./Appeal/New/TabBar")
})

const RecordRoomRoutes = [{
        path: "/recordroom/allappeals",
        component: RecordRoomAllAppeals,
        auth: authRoles.record_room
    },
    {
        path: "/recordroom/allcases",
        component: RecordRoomAllCases,
        auth: authRoles.record_room
    },
    {
        path: "/recordroom/newappeals/:id/:rec_id",
        component: RecordRoomNewAppeals,
        auth: authRoles.record_room
    },
    {
        path: "/recordroom/newappeals/:id",
        component: RecordRoomNewAppealsCase,
        auth: authRoles.record_room
    }
];

export default RecordRoomRoutes;