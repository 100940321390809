import React, { Component, Fragment } from "react";
import {
  Grid,
  Card
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import AddUnit from "./AddUnit";
import Button from '@material-ui/core/Button';
import UnitTable from "./UnitTable";

class Unit extends Component {
  state = {};

  render() {
    let { theme } = this.props;

    return (
      <Fragment>
         
        <div className="pb-10 pt-30 px-30 ">
          <UnitTable/>
        </div>
        <div className="pb-10 pt-30 px-30 ">
          <AddUnit/>
        </div>
      </Fragment>

   
    );
  }
}



export default withStyles({}, { withTheme: true })(Unit);
