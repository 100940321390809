import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const Call_types = MatxLoadable({
  loader: () => import("./Call_types")
})

const Call_typesRoutes = [
  {
    path: "/datasetup/call_types",
    component: Call_types,
    auth: authRoles.datasetup
  }
];

export default Call_typesRoutes;