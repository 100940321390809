import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const Meetingroom = MatxLoadable({
  loader: () => import("./Meetingroom")
})

const MeetingroomRoutes = [
  {
    path: "/datasetup/meeting_rooms",
    component: Meetingroom,
    auth: authRoles.datasetup
  }
];

export default MeetingroomRoutes;
