import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const ViewCases = MatxLoadable({
    loader: () =>
        import ("./ViewCases/ViewCase")
})

const NewCaseTab = MatxLoadable({
    loader: () =>
        import ("./AcceptCase/TabBar")
})

const AcceptedCaseTab = MatxLoadable({
    loader: () =>
        import ("./AcceptedCases/TabBar")
})
const RejectCaseTab = MatxLoadable({
    loader: () =>
        import ("./RejectedCases/TabBar")
})
const Summon = MatxLoadable({
    loader: () =>
        import ("./SummonView/Summon")
})
const SummonView = MatxLoadable({
    loader: () =>
        import ("./SummonView/SummonView")
})


const NewCasesList = MatxLoadable({
    loader: () =>
        import ("./RegisterdCases/NewCasesList")
})

const Registerd = MatxLoadable({
    loader: () =>
        import ("./RegisterdCases/TabBar")
})

const MACaseRoutes = [{
        path: "/officer/allcases",
        component: ViewCases,
        auth: authRoles.OfficerViewCases
    },

    {
        path: "/NewCase/Officer/:id",
        component: NewCaseTab,
        auth: authRoles.OfficerAcceptCase
    },

    {
        path: "/AcceptedCase/Officer/:id",
        component: AcceptedCaseTab,
        auth: authRoles.OfficerAcceptCase
    },
    {
        path: "/RejectedCase/Officer/:id",
        component: RejectCaseTab,
        auth: authRoles.OfficerAcceptCase
    },
    {
        path: "/SummonView/Officer",
        component: Summon,
        auth: authRoles.OfficerAcceptCase
    },
    {
        path: "/SummonView/Single/:id",
        component: SummonView,
        auth: authRoles.OfficerAcceptCase
    },
    {
        path: "/officer/registerd",
        component: NewCasesList,
        auth: authRoles.OfficerAcceptCase
    },
    {
        path: "/officer/registerd_case/:id",
        component: Registerd,
        auth: authRoles.registerd
    },


];

export default MACaseRoutes;