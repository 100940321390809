import { MatxLoadable } from "matx";
import { authRoles } from "../../../../auth/authRoles";

const CannotCase = MatxLoadable({
  loader: () => import("./CannotCase")
})

const CannotCaseRoutes = [
  {
    path: "/CannotCase/Director/:id",
    component: CannotCase,
    auth: authRoles.directorCannotCase
  }
];

export default CannotCaseRoutes;