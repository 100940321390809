import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Calendar = MatxLoadable({
    loader: () =>
        import ("./TabBar")
})

const CalendarRoutes = [{
        path: "/calendar",
        component: Calendar,
        auth: authRoles.all
    }

];

export default CalendarRoutes;