import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const NonImpAll = MatxLoadable({
    loader: () =>
        import ("./All/TabBar")
})

const NonImpAllBackreports = MatxLoadable({
    loader: () =>
        import ("./BackReport/TabBar")
})


const NonImpNewBackreports = MatxLoadable({
    loader: () =>
        import ("./New/TabBar")
})

const NonImpNewBackreportsCase = MatxLoadable({
    loader: () =>
        import ("./New/TabBar")
})


const NonImpRoutes = [{
        path: "/nonimp/all",
        component: NonImpAll,
        auth: authRoles.NonImp
    },
    {
        path: "/nonimp/backreports",
        component: NonImpAllBackreports,
        auth: authRoles.NonImp
    },
    {
        path: "/nonimp/newbackreport/:id/:rec_id",
        component: NonImpNewBackreports,
        auth: authRoles.NonImp
    },
    {
        path: "/nonimp/newbackreport/:id",
        component: NonImpNewBackreportsCase,
        auth: authRoles.NonImp
    }
];

export default NonImpRoutes;