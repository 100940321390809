import React, { Component } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { SimpleCard, MatxProgressBar } from "matx";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { withStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import FocalPointService from './../../../services/FocalPointService';
import FocalPointOfficerService from './../../../services/FocalPointOfficerService';


const styles = theme => ({
    cssLabel: {
        color: 'red'
    },

    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `${theme.palette.primary.main} !important`,
        }
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'blue !important'
    },

});
class FocalpointOfficer extends Component {


    state = {
        focalpointdepartments: [],
        snakebar: false,
        message: "",
        severity: "success",
        formdata: { name: "", title: "", designation: "", email: "", land_no: "", mobile_no: "", status: "", focal_point_department_id: null }
    };


    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snakebar: false })

    };
    componentDidMount() {
        this.loadFocalPoint();
    }

    loadFocalPoint() {//Loading all FocalPoint
        FocalPointService.getallFocalPoint().then((data) => {
            this.setState({ focalpointdepartments: data })
        });
    }

    handleChange = event => {
        event.persist();
        var data = this.state.formdata;

        data[event.target.name] = event.target.value;
        this.setState({
            formdata: data
        });

    };

    setdropdowndata(key, value) {
        var data = this.state.formdata;

        data[key] = value.id;
        this.setState({ formdata: data });

    }




    handleFormSubmit = event => {
        var data = this.state.formdata;

        FocalPointOfficerService.createFocalPointOfficer(data).then(res => {
            if (res.status == 200) {
                this.setState({ message: res.data.message, snakebar: true, severity: "success" })
                setTimeout(() => {
                    window.location.reload(false);
                }, 3000);
            } else {
                this.setState({ message: "Can not create officer", snakebar: true, severity: "error" })
            }
        })
            .catch((error) => {
                console.log("error", error);
            })

    }

    render() {
        const { classes } = this.props;
        return (
            <div>

                <SimpleCard title="Focal Point Officer">


                    <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={3} >
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    className="mb-24 w-100"
                                    options={this.state.focalpointdepartments}
                                    getOptionLabel={(option) => option.name}
                                    size='small'
                                    name="focal_point_department_id"
                                    onChange={(event, value) => this.setdropdowndata("focal_point_department_id", value)}
                                    renderInput={(params) => <TextValidator {...params} label="Department"
                                        value={this.state.formdata.focal_point_department_id}
                                        validators={["required"]}
                                        error
                                        helperText="Required Field "
                                        errorMessages={[
                                            "this field is required"
                                        ]}
                                        variant="outlined" />}
                                />
                                <TextValidator
                                    className="mb-24 w-100"
                                    variant="outlined"
                                    label="Officer Name"
                                    onChange={this.handleChange}
                                    size='small'
                                    name="name"
                                    value={this.state.formdata.name}
                                    error
                                    helperText="Required Field "
                                    validators={["required"]}
                                    errorMessages={[
                                        "this field is required"
                                    ]}
                                />
                                <TextValidator
                                    className="mb-24 w-100"
                                    variant="outlined"
                                    label="Title"
                                    onChange={this.handleChange}
                                    size='small'
                                    name="title"
                                    value={this.state.formdata.title}
                                    error
                                    helperText="Required Field "
                                    validators={["required"]}
                                    errorMessages={[
                                        "this field is required"
                                    ]}
                                />
                                <TextValidator
                                    className="mb-24 w-100"
                                    variant="outlined"
                                    label="Designation"
                                    onChange={this.handleChange}
                                    size='small'
                                    name="designation"
                                    value={this.state.formdata.designation}
                                    error
                                    helperText="Required Field "
                                    validators={["required"]}
                                    errorMessages={[
                                        "this field is required"
                                    ]}
                                />

                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <TextValidator
                                    className="mb-24 w-100"
                                    variant="outlined"
                                    label="Email"
                                    onChange={this.handleChange}
                                    size='small'
                                    type="email"
                                    name="email"
                                    value={this.state.formdata.email}
                                    //validators={["required"]}
                                    errorMessages={[
                                        "this field is required"
                                    ]}
                                />
                                <TextValidator
                                    className="mb-24 w-100"
                                    variant="outlined"
                                    label="Land line Number"
                                    onChange={this.handleChange}
                                    size='small'
                                    name="landline"
                                    value={this.state.formdata.landline}
                                    type="number"
                                    // validators={["required"]}
                                    errorMessages={[
                                        "this field is required"
                                    ]}
                                />
                                <TextValidator
                                    className="mb-24 w-100"
                                    variant="outlined"
                                    label="Mobile Number"
                                    onChange={this.handleChange}
                                    size='small'
                                    name="mobile_no"
                                    type="number"
                                    value={this.state.formdata.mobile_no}
                                    //validators={["required"]}
                                    errorMessages={[
                                        "this field is required"
                                    ]}
                                />

                            </Grid>
                        </Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            type="submit"
                            startIcon={<SaveIcon />}
                        >
                            Save
                        </Button>


                    </ValidatorForm>
                </SimpleCard>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.snakebar} autoHideDuration={2500} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity={this.state.severity}>
                        {this.state.message}
                    </Alert>
                </Snackbar>

            </div>
        );
    };

}

FocalpointOfficer.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(FocalpointOfficer);
