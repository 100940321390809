import React, { Component } from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  Button,
  Icon,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import "date-fns";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import SaveIcon from '@material-ui/icons/Save';
import { SimpleCard } from "matx";
import { withStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import UnitService from './../../../services/UnitService';

const styles = theme => ({
  cssLabel: {
    color: 'red'
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    }
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'blue !important'
  },

});
class AddUnit extends Component {
  state = {
    snakebar: false,
    message: "",
    severity: "success",
    formdata: { name: "" }
  };
  componentDidMount() {

  }
  handleChange = event => {
    event.persist();
    var data = this.state.formdata;

    data[event.target.name] = event.target.value;
    this.setState({
      formdata: data
    });


  };
  setdropdowndata(key, value) {
    var data = this.state.formdata;
    if (value == null) {
      data[key] = "";
      this.setState({ formdata: data });
    } else {
      data[key] = value.title;
      this.setState({ formdata: data });
    }
  }
  //Create new units
  handleFormSubmit = event => {
    var data = this.state.formdata;

    UnitService.createunit(data).then(res => {
      if (res.status == 200) {
        this.setState({ message: res.data.message, snakebar: true, severity: "success" })
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        this.setState({ message: "Can not create unit", snakebar: true, severity: "error" })
      }
    })
      .catch((error) => {
        console.log("error", error);
      })

  }
  handleSubmit = event => {
    console.log("submitted");
    console.log(event);
  };

  handleDateChange = date => {
    console.log(date);

    this.setState({ date });
  };
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ snakebar: false })

  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <SimpleCard title="Unit">
          <ValidatorForm
            ref="form"
            onSubmit={this.handleFormSubmit}
            onError={errors => null}
          >
            <Grid container spacing={6}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextValidator
                  className="mb-16 w-100"
                  label="Unit"
                  onChange={this.handleChange}
                  type="text"
                  name="name"
                  size="small"
                  value={this.state.formdata.name}
                  error
                  helperText="Required Field "
                  variant="outlined"
                  validators={[
                    "required",
                  ]}
                  errorMessages={["this field is required"]}
                />

              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              size="small"
              type="submit"
              startIcon={<SaveIcon />}
            >
              Save
                        </Button>


          </ValidatorForm>
        </SimpleCard>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.snakebar} autoHideDuration={2500} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity={this.state.severity}>
            {this.state.message}
          </Alert>
        </Snackbar>
      </div>

    );
  }
}


AddUnit.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(AddUnit);
