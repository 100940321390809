import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const Categories = MatxLoadable({
  loader: () => import("./Categories")
})

const RegionalOfficeRoutes = [
  {
    path: "/datasetup/categories",
    component: Categories,
    auth: authRoles.datasetup
  }
];

export default RegionalOfficeRoutes;
