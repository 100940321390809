import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";
import Organization from "./Organization";

const organization = MatxLoadable({
  loader: () => import("./Organization")
})

const organizationroute = [
  {
    path: "/datasetup/organization",
    component: Organization,
    auth: authRoles.datasetup
  }
];

export default organizationroute;