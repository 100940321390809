import { MatxLoadable } from "matx";
import { authRoles } from "../../../../auth/authRoles";

const CanCase = MatxLoadable({
  loader: () => import("./CanCase")
})

const CanCaseRoutes = [
  {
    path: "/CanCase/Director/:id",
    component: CanCase,
    auth: authRoles.directorCanCase
  }
];

export default CanCaseRoutes;