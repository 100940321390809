import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const NewAppeal = MatxLoadable({
    loader: () =>
        import ("./New/TabBar")
})

const AllAppeal = MatxLoadable({
    loader: () =>
        import ("./All/Commissioner/TabBar")
})

const AllAppealOfficer = MatxLoadable({
    loader: () =>
        import ("./All/Officer/TabBar")
})

const AppealRoutes = [{
        path: "/appeal/newappeal/:id/:rec_id",
        component: NewAppeal,
        auth: authRoles.AppealView
    },
    {
        path: "/appeal/allappeal",
        component: AllAppeal,
        auth: authRoles.AppealAll
    },
    {
        path: "/appeal/officer/allappeal",
        component: AllAppealOfficer,
        auth: authRoles.AppealOfficer
    }
];

export default AppealRoutes;