import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import moment from "moment";
import SearchIcon from '@material-ui/icons/Search';
import { Grid, Button, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { SimpleCard } from "matx";
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextValidator from 'react-material-ui-form-validator/lib/TextValidator';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TimeLine from "./TimeLine/TimeLine";
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from "@material-ui/styles";
import Box from '@material-ui/core/Box';
import DocIcon from '@material-ui/icons/Description';
import Link from '@material-ui/core/Link';

import Service from '../../services/ExternalRequestService'
import * as appconst from '../../appconst'

const stylesDialogTitle = (theme) => ({
  root: {
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitleWithCloseButton = withStyles(stylesDialogTitle)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton color="secondary" aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon
            color="error"
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

class Track extends Component {

  state = {
    tab: 0,
    case_id: null,
    find_case: {
      case_no: null,
      can_details: null,
      case_uploads: [],
      complainers: [],
      victims: [],
      respondents: [],
      others: [],
      case: null
    },
    otp_no: null,
    show_otp: false,
    show_data: false,
    btn_disable: false,
    is_show_mobile: false,
    snakebar: false,
    severity: null,
    message: null,
    form_data: {
      case_no: null,
      mobile: null
    },
    form_data_otp: {
      otp: null,
      type: null,
      msg_english: null,
      msg_sinhala: null,
      msg_tamil: null,
    },
    time_line: []
  }

  componentDidMount() {
    this.setState({
      tab: 0,
      case_id: null,
      find_case: {
        case_no: null,
        can_details: null,
        case_uploads: [],
        complainers: [],
        victims: [],
        respondents: [],
        others: [],
        case: null
      },
      otp_no: null,
      show_otp: false,
      show_data: false,
      btn_disable: false,
      is_show_mobile: false,
      snakebar: false,
      severity: null,
      message: null,
      form_data: {
        case_no: this.state.form_data.case_no,
        mobile: null
      },
      form_data_otp: {
        otp: null,
        type: null,
        msg_english: null,
        msg_sinhala: null,
        msg_tamil: null,
      },
      time_line: []
    })
  }

  handleChange = event => {
    event.persist();
    var data = this.state.form_data;
    data[event.target.name] = event.target.value;
    this.setState({
      form_data: data
    })

  };

  handleChangeOtp = event => {

    const reg = /^[0-9\b]+$/;
    if (event.target.value === '' || reg.test(event.target.value)) {

      event.persist();
      var data = this.state.form_data_otp;
      data[event.target.name] = event.target.value;
      data["type"] = null;

      this.setState({
        form_data_otp: data
      })
    }
  };


  submit() {
    this.find_case()
  }

  find_case() {

    this.setState({
      tab: 0,
      case_id: null,
      find_case: {
        case_no: null,
        can_details: null,
        case_uploads: [],
        complainers: [],
        victims: [],
        respondents: [],
        others: [],
        case: null
      },
      otp_no: null,
      show_otp: false,
      show_data: false,
      btn_disable: true,
      is_show_mobile: false,
      snakebar: false,
      severity: null,
      message: null,
      form_data_otp: {
        otp: null,
        type: null,
        msg_english: null,
        msg_sinhala: null,
        msg_tamil: null,
      },
      time_line: []
    })

    let params = this.state.form_data
    Service.find_case(params).then((res) => {
      if (res === 500) {
        this.setState({
          snakebar: true,
          severity: "error",
          message: "Something went wrong",
          btn_disable: false
        })
      } else {
        if (!res) {
          this.setState({
            snakebar: true,
            severity: "error",
            message: "Case number is incorrect. නඩු අංකය වැරදියි. வழக்கு எண் தவறு",
            btn_disable: false
          })
        } else {
          this.getCase()
          // this.match_mobile_no()
        }
      }

    });
  }

  match_mobile_no() {
    let params = this.state.form_data
    Service.match_mobile(params).then((res) => {
      if (res === 500) {
        this.setState({
          snakebar: true,
          severity: "error",
          message: "Something went wrong",
          btn_disable: false
        })
      } else {
        if (!res) {
          this.setState({
            snakebar: true,
            severity: "error",
            message: "Please note that the mobile number you entered is not the mobile number registered with the relevant case. ඔබ ඇතුලත් කල ජංගම දූරකථන අංකය අදාල පැමිණිල්ල සමග ලියාපදින්චි ජංගම දූරකථන අංකය නොවන බව කරුණාවෙන් සලකන්න. நீங்கள் உள்ளிட்ட மொபைல் எண், தொடர்புடைய வழக்கில் பதிவுசெய்யப்பட்ட மொபைல் எண் அல்ல என்பதை நினைவில் கொள்ளவும்",
            btn_disable: false
          })
        } else {
          this.sendOTP()
        }
      }

    });
  }

  sendOTP() {

    let params = this.state.form_data
    Service.sendOtp(params.mobile).then((res) => {
      if (res && res.referenceId) {
        this.setState({
          btn_disable: false,
          show_otp: true,
          otp_no: res.referenceId
        })
      } else {
        this.setState({
          snakebar: true,
          severity: "error",
          message: "Something went wrong",
          btn_disable: false
        })
      }

    });
  }

  checkOTP() {

    this.setState({
      btn_disable: true,
    })

    let params = {
      otp: this.state.form_data_otp.otp,
      referenceId: this.state.otp_no
    }

    Service.checkOTP(params).then((res) => {

      if (res) {

        if (res.statusCode == "1000") {
          this.getCase()
        } else if (res.statusCode == "1001") {

          var data = this.state.form_data_otp;
          data["otp"] = data["otp"];
          data["msg_english"] = "The OTP you entered is incorrect please check and re-enter."
          data["msg_sinhala"] = "ඔබ ඇතුලත් කල OTP අංකය වැරදී, කරුණාකර නැවත පරික්ශා කර ඇතුලත් කරන්න."
          data["msg_tamil"] = "நீங்கள் உள்ளிட்ட OTP தவறானது, சரிபார்த்து மீண்டும் உள்ளிடவும்."
          data["type"] = "mismatch";

          this.setState({
            form_data_otp: data,
            btn_disable: false
          })

        } else {
          this.setState({
            snakebar: true,
            severity: "error",
            message: "Something went wrong",
            btn_disable: false
          })
        }

      } else {
        this.setState({
          snakebar: true,
          severity: "error",
          message: "Something went wrong",
          btn_disable: false
        })
      }

    });
  }

  getCase() {

    let params = this.state.form_data
    Service.get_case(params).then((res) => {
      if (res === 500) {
        this.setState({
          snakebar: true,
          severity: "error",
          message: "Something went wrong",
          btn_disable: false
        })
      } else {
        if (!res) {
          this.setState({
            snakebar: true,
            severity: "error",
            message: "Something went wrong",
            btn_disable: false
          })
        } else {

          let complainers = []
          let victims = []
          let respondents = []
          let others = []

          if (res[0].case_complainers) {
            for (let i = 0; i < res[0].case_complainers.length; i++) {
              let row = res[0].case_complainers[i]
              if (res[0].case_complainers[i].pivot.iscomplainer != null) {
                complainers.push({
                  id: complainers.length,
                  name: row.name,
                  organization: row.organization,
                  // gender: row.gender,
                  // age: row.age,
                  // address: row.address,
                  // contact: row.contact_no,
                  // email: row.email,
                  // nic: row.nic,
                  // district: row.district
                })
              } else if (res[0].case_complainers[i].pivot.isvictim != null) {
                victims.push({
                  id: victims.length,
                  name: row.name,
                  organization: row.organization,
                  // gender: row.gender,
                  // age: row.age,
                  // address: row.address,
                  // contact: row.contact_no,
                  // email: row.email,
                  // nic: row.nic,
                  // district: row.district
                })
              } else if (res[0].case_complainers[i].pivot.isrespondent != null) {
                respondents.push({
                  id: respondents.length,
                  name: row.name,
                  organization: row.organization,
                  // gender: row.gender,
                  // address: row.address,
                  // contact: row.contact_no,
                  // email: row.email,
                  // nic: row.nic,
                  // district: row.district,
                  // type: row.organization_type,
                  // designation: row.designation,
                  // province: row.province,
                })
              } else if (res[0].case_complainers[i].pivot.isother != null) {
                others.push({
                  id: complainers.length,
                  name: row.name,
                  organization: row.organization,
                  // gender: row.gender,
                  // address: row.address,
                  // contact: row.contact_no,
                  // email: row.email,
                  // nic: row.nic,
                  // district: row.district,
                  // type: row.organization_type,
                  // designation: row.designation,
                  // province: row.province,
                })
              }
            }
          }

          this.setState({
            tab: 0,
            case_id: res[0].id,
            find_case: {
              case_no: res[0].case_no,
              can_details: res[0].can_details,
              case_uploads: [],
              // case_uploads: res[0].case_uploads,
              complainers: complainers,
              victims: victims,
              respondents: respondents,
              others: others,
              case: res[0]
            },
          })
          this.get_time_line(res[0].id)
        }
      }
    });

  }

  get_time_line(id) {

    let temp = []

    Service.get_timeline(id).then((res) => {
      if (res === 500) {
        this.get_time_line(id)
      } else {
        let data = res
        for (let i = 0; i < data.length; i++) {

          let days = 0
          if (i + 1 != data.length) {
            days = this.get_days(
              data[i].created_at, data[i + 1].created_at
            )
          }
          temp.push({
            id: i + 1,
            text: data[i].action,
            date: data[i].created_at,
            days: days
          })

        }

        this.state.time_line = temp

        this.setState({
          show_otp: false,
          show_data: true,
          btn_disable: false,
        })
      }

    });
  }

  get_days(date1, date2) {

    var d1 = moment(date1);
    var d2 = moment(date2);

    var count = 0
    var hours = 0
    var minutes = 0
    var seconds = 0
    var days = d2.diff(d1, 'days')

    if (days != 0) {
      count = days > 1 ? days + " Days" : days + " Day"
    } else {
      hours = d2.diff(d1, 'hours')
      if (hours != 0) {
        count = hours > 1 ? hours + " Hours" : hours + " Hour"
      } else {
        minutes = d2.diff(d1, 'minutes')
        if (minutes != 0) {
          count = minutes > 1 ? minutes + " Minutes" : minutes + " Minute"
        } else {
          seconds = d2.diff(d1, 'seconds')
          count = seconds > 1 ? seconds + " Seconds" : seconds + " Second"
        }

      }
    }


    return count

  }

  return_date(date) {
    return moment(date).format("YYYY-MM-DD")
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tab: newValue })
  };

  render() {

    return (
      <div className="signup" style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "100vh",
        width: "100vw",
      }}>
        <div className="pb-30 pt-30 px-30">
          <Paper >
            <div className="pb-30 pt-30 px-30">
              <div style={{
                display: "flex", flexDirection: "row", justifyContent: "center"
              }}>
                <div className='mr-20'>
                  <img src="/assets/images/summonlogo2.jpg" height={100} width={100}>
                  </img>
                </div>

                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <strong>
                    <h5 className='m-0'>
                      Human Rights Commission Of Sri Lanka.
                    </h5>
                    <h5 className='m-0'>
                      ශ්‍රී ලංකා මානව හිමිකම් කොමිෂන් සභාව.
                    </h5>
                    <h5 className='m-0'>
                      இலங்கை மனித உரிமைகள் ஆணைக்குழு.
                    </h5>
                  </strong>
                </div>

              </div>

              <div className="pt-20">
                <strong>Instructions/උපදෙස්/அறிவுறுத்தல்கள்</strong>
                <ul>
                  <li>
                    You have to be a Complainant or Victim that involves with the case to track the progress of the complaint and have a mobile phone number that you have registered to confirm your identity.
                    <br />
                    පැමිණිල්ලේ ප්‍රගතිය නිරීක්ෂණය කිරීම සඳහා ඔබ පැමිණිල්ලට සම්බන්ධ පැමිණිලිකරු හෝ වින්දිත විය යුතු අතර ඔබේ අනන්යතාවය තහවුරු කිරීමට ඔබ ලියාපදිංචි කර ඇති ජංගම දුරකථන අංකයක් තිබිය යුතුය
                    <br />
                    முறைப்பாட்டின் முன்னேற்றத்தைக் கண்காணிக்க தாங்கள் முறைப்பாட்டுடன் சம்பந்தமான  முறைப்பாட்டாளர் அல்லது பாதிக்கப்பட்டவராக இருப்பதுடன் உங்கள் அடையாளத்தை நிரூபிக்க நீங்கள் பதிவு செய்த கையடக்கத்தொலைபேசி எண்ணை வைத்திருக்க வேண்டும்.
                  </li>
                  <br />
                  <li>
                    Please enter the complaint number that you wish to track the case progress.
                    <br />
                    කරුණාකර ප්‍රගතිය නිරීක්ෂණය කිරීමට අවශ්‍ය පැමිණිලි අංකය ඇතුලත් කරන්න.
                    <br />
                    முறைப்பாட்டின் முன்னேற்றத்தைக் அறிந்துகொள்ள விரும்பின் தயவுசெய்து முறைப்பாட்டு எண்ணைப் பதியவும்.
                  </li>
                  <br />
                  <li>
                    Please enter the mobile number you entered when registering the case.
                    <br />
                    කරුණාකර පැමිණිල්ල ලියාපදිංචි කිරීමේදී ඔබ ඇතුළත් කළ ජංගම දුරකථන අංකය ඇතුළත් කරන්න.
                    <br />
                    முறைப்பாட்டினை பதிவு செய்த போது நீங்கள் பதிவு செய்த கையடக்கத்தொலைபேசி  எண்ணை தயவுசெய்து  பதியவும்.
                  </li>
                  <br />
                </ul>
              </div>


              <div className="pt-30">
                <ValidatorForm ref="form" onSubmit={() => this.submit()}>
                  <Grid container spacing={2} >
                    <Grid item xs={12} sm={6} md={4}>
                      <TextValidator
                        className="mb-20 w-100"
                        label="Case Number/පැමිණිලි අංකය/முறைப்பாட்டு எண்"
                        type="text"
                        name="case_no"
                        onChange={this.handleChange}
                        value={this.state.form_data.case_no}
                        variant="outlined"
                        size='small'
                        validators={
                          ["required"]
                        }
                        errorMessages={[
                          "This field is required"
                        ]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextValidator
                        className="mb-20 w-100"
                        label="Mobile Number/ජංගම දූරකථන අංකය/ கையடக்கத்தொலைபேசி  எண்"
                        type="text"
                        name="mobile"
                        onChange={this.handleChange}
                        value={this.state.form_data.mobile}
                        variant="outlined"
                        size='small'
                        helperText="712345678"
                        validators={["required", "matchRegexp:((^|, )([7][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]))+$"]}
                        errorMessages={
                          ["This field is required", "Invalid mobile no"]
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Button
                        disabled={this.state.btn_disable}
                        variant="contained"
                        color="primary"
                        size="small"
                        type="submit"
                        startIcon={<SearchIcon />}
                      >Search/පිරික්සන්න/தேடல்</Button>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </div>


              <Dialog
                fullWidth={true}
                open={this.state.show_otp}
              >

                <DialogTitleWithCloseButton
                  onClose={() => {
                    this.setState({
                      show_otp: false
                    })
                  }}
                >
                </DialogTitleWithCloseButton>
                <div className="p-30 w-100">
                  <ul>
                    <li>
                      Please enter the OTP number you get on your registered mobile via SMS.
                      <br />
                      SMS මගින් ලියාපදින්චි ජංගම දුරකථනය වෙත ලැබුනු OTP කේතය ඇතුලත් කරන්න.
                      <br />
                      நீங்கள் பதிவு செய்த மொபைலில் SMS மூலம் கிடைக்கும் OTP எண்ணை உள்ளிடவும்
                    </li>
                    <br />
                  </ul>
                  <ValidatorForm ref="form" onSubmit={() => this.checkOTP()}>
                    <Grid container spacing={2} >
                      <Grid item xs={12} sm={12}>
                        <TextValidator
                          className="mb-10 w-100"
                          label="OTP"
                          type="number"
                          minValue={0}
                          name="otp"
                          onChange={this.handleChangeOtp}
                          value={this.state.form_data_otp.otp}
                          variant="outlined"
                          size='small'
                          validators={["required"]}
                          errorMessages={[
                            "this field is required"
                          ]}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />

                      </Grid>
                      <Grid item xs={12} sm={12} >
                        <Button
                          disabled={this.state.btn_disable}
                          variant="contained"
                          color="primary"
                          size="small"
                          type="submit"
                          startIcon={<SearchIcon />}
                        > Submit/ඉදිරිපත් කරන්න/தயவு செய்து சரி பார்க்கவும்</Button>
                      </Grid>
                      <Grid item xs={12} sm={12} >
                        {this.state.form_data_otp.type ?
                          <div>
                            <p className='m-0' style={{ color: this.state.form_data_otp.type == "mismatch" ? "red" : "green" }}>
                              {
                                this.state.form_data_otp.msg_english
                              }
                            </p>
                            <p className='m-0' style={{ color: this.state.form_data_otp.type == "mismatch" ? "red" : "green" }}>
                              {
                                this.state.form_data_otp.msg_sinhala
                              }
                            </p>
                            <p className='m-0' style={{ color: this.state.form_data_otp.type == "mismatch" ? "red" : "green" }}>
                              {
                                this.state.form_data_otp.msg_tamil
                              }
                            </p>
                          </div>
                          : null
                        }
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </div>
              </Dialog>

              <Dialog
                fullScreen
                open={this.state.show_data}
              >

                <DialogTitleWithCloseButton
                  onClose={() => {
                    this.setState({
                      show_data: false
                    })
                  }}
                >
                </DialogTitleWithCloseButton>
                <div className="p-30 w-100">
                  <ul>
                    Case Number/පැමිණිලි අංකය/ முறைப்பாட்டு எண்
                    <br />
                    <h3>
                      {this.state.find_case.case_no ? this.state.find_case.case_no : null}
                    </h3>
                    <br />
                    {this.state.find_case.can_details ?
                      <li>
                        Your case is handled by officer/පරීක්ෂණ නිලධාරි/விசாரணை உத்தியோகத்தர்
                        <br />
                        {this.state.find_case.can_details ?
                          <label style={{ fontWeight: "bolder" }}>
                            {this.state.find_case.can_details.assigned_officer[0].first_name + " " +
                              this.state.find_case.can_details.assigned_officer[0].last_name}
                          </label>
                          : null
                        }
                      </li>
                      : null}
                    {this.state.find_case.can_details ?
                      <br />
                      : null}
                    <li>
                      The last action taken for the complaint  is &nbsp;
                      {
                        this.state.time_line.length > 0 ?
                          <label style={{ fontWeight: "bolder" }}>
                            {this.state.time_line[this.state.time_line.length - 1].text}
                          </label>
                          : null
                      }
                      &nbsp; on &nbsp;
                      {
                        this.state.time_line.length > 0 ?
                          <label style={{ fontWeight: "bolder" }}>
                            {this.return_date(this.state.time_line[this.state.time_line.length - 1].date)}
                          </label>
                          : null
                      }
                      .
                      <br />
                      පැමිණිල්ල සදහා &nbsp;
                      {
                        this.state.time_line.length > 0 ?
                          <label style={{ fontWeight: "bolder" }}>
                            {this.return_date(this.state.time_line[this.state.time_line.length - 1].date)}
                          </label>
                          : null
                      }
                      &nbsp; දින  &nbsp;
                      {
                        this.state.time_line.length > 0 ?
                          <label style={{ fontWeight: "bolder" }}>
                            {this.state.time_line[this.state.time_line.length - 1].text}
                          </label>
                          : null
                      }
                      &nbsp;කිරීමක් සිදු කර ඇත.
                      <br />
                      வழக்குக்காக எடுக்கப்பட்ட கடைசி படி  &nbsp;
                      {
                        this.state.time_line.length > 0 ?
                          <label style={{ fontWeight: "bolder" }}>
                            {this.state.time_line[this.state.time_line.length - 1].text}
                          </label>
                          : null
                      }
                      &nbsp; அன்று &nbsp;
                      {
                        this.state.time_line.length > 0 ?
                          <label style={{ fontWeight: "bolder" }}>
                            {this.return_date(this.state.time_line[this.state.time_line.length - 1].date)}
                          </label>
                          : null
                      }
                      .

                    </li>
                    <br />
                    <li>
                      The complaint progress can be seen below.
                      <br />
                      පැමිණිල්ල මෙතෙක් ප්‍රගතිය පහත දැක්වේ
                      <br />
                      முறைப்பாட்டின்  முன்னேற்றத்தை கீழே பார்க்க முடியும்.
                    </li>
                  </ul>
                  <br />

                  <Box className="px-10 mt-10" bgcolor="text.customColor1" mb={2}>
                    <Typography variant="h6" gutterBottom>Case Details/පැමිණිල්ලේ විස්තර/ முறைப்பாட்டு விபரங்கள்</Typography>
                  </Box>

                  <div className='p-20'>
                    <Grid container spacing={2} >
                      <Grid item xs={12} sm={12} md={12}>

                        <TableContainer component={Paper}>
                          <Table className='p-20' size="small" stickyHeader>

                            <TableRow>
                              <TableCell align="left" style={{ borderBottom: 0 }}>
                                Case registration
                                <br />
                                පැමිණිල්ල ලියාපදිංචි කිරීම
                                <br />
                                முறைப்பாட்டை பதிவு செய்தல்
                              </TableCell>
                              <TableCell align="left" style={{ borderBottom: 0 }}>
                                {
                                  this.state.find_case.case ?
                                    moment(this.state.find_case.case.complained_time).format("YYYY-MM-DD h:mm A")
                                    : null
                                }
                              </TableCell>
                              <TableCell align="left" style={{ borderBottom: 0 }}>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="left" style={{ borderBottom: 0 }}>
                                Decision to Proceed case
                                <br />
                                පැමිණිල්ල ඉදිරියට ගෙන යාමට තීරණය
                                <br />
                                முறைப்பாட்டை  முன்னெடுத்துச்செல்வதற்கான தீர்மானம்
                              </TableCell>
                              <TableCell align="left" style={{ borderBottom: 0 }}>
                                {
                                  this.state.find_case.case ?
                                    this.state.find_case.case.accepted_as == "Can Case" ?
                                      "Complaint can be proceed" + " " :
                                      this.state.find_case.case.accepted_as == "Cannot Case" ?
                                        "Complaint can not be proceed" + " " :
                                        this.state.find_case.case.accepted_as == "Focal Point" ?
                                          "Complaint directed to a focal point" + " "
                                          : null
                                    : null
                                }
                              </TableCell>
                              <TableCell align="left" style={{ borderBottom: 0 }}>
                                {
                                  this.state.find_case.case ?
                                    this.state.find_case.case.accepted_as == "Can Case" ?
                                      moment(this.state.find_case.case.can_details.created_at).format("YYYY-MM-DD") :
                                      this.state.find_case.case.accepted_as == "Cannot Case" ?
                                        moment(this.state.find_case.case.cannot_details.created_at).format("YYYY-MM-DD") :
                                        this.state.find_case.case.accepted_as == "Focal Point" ?
                                          moment(this.state.find_case.case.focalpoint_cases.created_at).format("YYYY-MM-DD")
                                          : null
                                    : null
                                }
                              </TableCell>
                            </TableRow>

                            {this.state.find_case.case && this.state.find_case.case.accepted_as == "Cannot Case" ?

                              <TableRow>
                                <TableCell align="left" style={{ borderBottom: 0 }}>
                                  Cannot letter
                                  <br />
                                  ලිපිය
                                  <br />
                                  கடிதம்
                                </TableCell>
                                <TableCell align="left" style={{ borderBottom: 0 }}>
                                  {
                                    this.state.find_case.case.cannot_details.letter_status
                                  }
                                </TableCell>
                                <TableCell align="left" style={{ borderBottom: 0 }}>
                                  {
                                    this.state.find_case.case.cannot_details.letters && this.state.find_case.case.cannot_details.letters.length > 0 ?
                                      moment(this.state.find_case.case.cannot_details.letters[0].letter_date).format("YYYY-MM-DD")
                                      : null
                                  }
                                </TableCell>
                              </TableRow>
                              : null}

                            <div className='mt-30'></div>
                            <TableRow>
                              <TableCell align="left" style={{ borderBottom: 0 }}>
                                <strong className='mt-10'>Callings/කැදවීම්/அழைப்புகள் {this.state.find_case.case && this.state.find_case.case.callings.length == 0 ? "( Due )" : null}</strong>
                              </TableCell>
                            </TableRow>
                            {
                              this.state.find_case.case && this.state.find_case.case.callings ?
                                this.state.find_case.case.callings.map((row) => (
                                  <TableRow>
                                    <TableCell align="left" style={{ borderBottom: 0 }}> {row.calltype.name}</TableCell>
                                    <TableCell align="left" style={{ borderBottom: 0 }}> {row.status == "Recieved" ? "Report received" : row.status}</TableCell>
                                    <TableCell align="left" style={{ borderBottom: 0 }}> {moment(row.created_at).format("YYYY-MM-DD")}</TableCell>
                                  </TableRow>
                                ))
                                : null
                            }


                            <div className='mt-10'></div>
                            <TableRow>
                              <TableCell align="left" style={{ borderBottom: 0 }}>
                                <strong className='mt-10'>Inquiry/පරීක්ෂණ/சோதனைகள்</strong>
                              </TableCell>
                            </TableRow>
                            {
                              this.state.find_case.case && this.state.find_case.case.summons ?
                                this.state.find_case.case.summons.map((row) => (
                                  <TableRow>
                                    <TableCell align="left" style={{ borderBottom: 0 }}> {row.status}</TableCell>
                                    <TableCell align="left" style={{ borderBottom: 0 }}> {row.date ? moment(row.date).format("YYYY-MM-DD") : null}</TableCell>
                                    <TableCell align="left" style={{ borderBottom: 0 }}> </TableCell>
                                  </TableRow>
                                ))
                                : null
                            }

                            <div className='mt-10'></div>
                            <TableRow>
                              <TableCell align="left" style={{ borderBottom: 0 }}>
                                <strong className='mt-10'>Recommendation/නිර්දේශය/பரிந்துரை {this.state.find_case.case && this.state.find_case.case.recomandation.length == 0 ? "( Due )" : null}</strong>
                              </TableCell>
                            </TableRow>
                            {
                              this.state.find_case.case && this.state.find_case.case.recomandation ?
                                this.state.find_case.case.recomandation.map((row) => (
                                  row.issue_date && row.recomondations_type == "Issue" ?
                                    <TableRow>
                                      <TableCell align="left" style={{ borderBottom: 0 }}></TableCell>
                                      <TableCell align="left" style={{ borderBottom: 0 }}> {"Recommendation issued by HRC"}</TableCell>
                                      <TableCell align="left" style={{ borderBottom: 0 }}> {moment(row.issue_date).format("YYYY-MM-DD")}</TableCell>
                                    </TableRow>
                                    : null
                                ))
                                : null
                            }

                          </Table>
                        </TableContainer>

                      </Grid>
                    </Grid>
                  </div>

                  <br />
                  {/* <Box className="px-10 mt-10" bgcolor="text.customColor1" mb={2}>
                    <Typography variant="h6" gutterBottom>Timeline/ගමන් මග/காலவரிசையை</Typography>
                  </Box>
                  <TimeLine case_id={this.state.case_id} time_line={this.state.time_line}></TimeLine>
                  <br /> */}

                  {/* <Box className="px-10 mt-10" bgcolor="text.customColor1" mb={2}>
                    <Typography variant="h6" gutterBottom>Document Details/ලේඛන විස්තර/ஆவண விவரங்கள்</Typography>
                  </Box>

                  <Grid container spacing={3} className="my-20" >
                    {this.state.find_case.case_uploads.map((row) => (
                      row.upload_type === 'Case Uploads' ?
                        <Grid item xs={12} sm={4} md={2}  >
                          <Tooltip title={row.name} arrow placement="top">
                            <Card style={{
                              padding: 16,
                              boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 6%), 0px 6px 10px 0px rgb(0 0 0 / 4%), 0px 1px 18px 0px rgb(0 0 0 / 4%)"
                            }}>
                              <Link color="inherit" underline="none" target="_blank" href={appconst.CASEDOCUMENT + this.state.case_id + "/" + row.upload_url} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <DocIcon color="primary" style={{ marginRight: 8, }} /> <Fragment >{row.name && row.name.length > 10 ? row.name.substr(0, 10) + " ..." : row.name}</Fragment>
                              </Link>
                            </Card>
                          </Tooltip>
                        </Grid>
                        : null))}
                  </Grid> */}

                  <Box className="px-10 mt-10" bgcolor="text.customColor1" mb={2}>
                    <Typography variant="h6" gutterBottom>Participant details/සහභාගිවන්නන්ගේ විස්තර/பங்கேற்பாளர் விவரங்கள்</Typography>
                  </Box>
                  <Grid container spacing={3} className="my-20" >
                    <Grid item xs={12} sm={12} md={4}  >
                      <SimpleCard title="Compliners Details">
                        <TableContainer component={Paper}>
                          <Table size="small" stickyHeader>
                            <TableHead>
                              <TableRow  >
                                <TableCell align="center" >Name</TableCell>
                                <TableCell align="center" >Organization</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.find_case.complainers.length > 0 ?
                                this.state.find_case.complainers.map((row) => (
                                  <TableRow >
                                    <TableCell align="left"> {row.name}</TableCell>
                                    <TableCell align="left"> {row.organization}</TableCell>
                                  </TableRow>
                                ))
                                :
                                <TableRow >
                                  <TableCell align="center" colSpan={2} style={{ fontSize: "12px", fontStyle: "italic" }}> No data to display</TableCell>
                                </TableRow>
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </SimpleCard>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}  >
                      <SimpleCard title="Victims Details">
                        <TableContainer component={Paper}>
                          <Table size="small" stickyHeader>
                            <TableHead>
                              <TableRow  >
                                <TableCell align="center" >Name</TableCell>
                                <TableCell align="center" >Organization</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.find_case.victims.length > 0 ?
                                this.state.find_case.victims.map((row) => (
                                  <TableRow >
                                    <TableCell align="left"> {row.name}</TableCell>
                                    <TableCell align="left"> {row.organization}</TableCell>
                                  </TableRow>
                                ))
                                :
                                <TableRow >
                                  <TableCell align="center" colSpan={2} style={{ fontSize: "12px", fontStyle: "italic" }}> No data to display</TableCell>
                                </TableRow>
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </SimpleCard>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}  >
                      <SimpleCard title="Respondents Details" >
                        <TableContainer component={Paper}>
                          <Table size="small" stickyHeader >
                            <TableHead>
                              <TableRow  >
                                <TableCell align="center" >Name</TableCell>
                                <TableCell align="center" >Organization</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.find_case.respondents.length > 0 ?
                                this.state.find_case.respondents.map((row) => (
                                  <TableRow >
                                    <TableCell align="left"> {row.name}</TableCell>
                                    <TableCell align="left"> {row.organization}</TableCell>
                                  </TableRow>
                                ))
                                :
                                <TableRow >
                                  <TableCell align="center" colSpan={2} style={{ fontSize: "12px", fontStyle: "italic" }}> No data to display</TableCell>
                                </TableRow>
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </SimpleCard>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}  >
                      <SimpleCard title="Others Details">
                        <TableContainer component={Paper}>
                          <Table size="small" stickyHeader >
                            <TableHead>
                              <TableRow  >
                                <TableCell align="center" >Name</TableCell>
                                <TableCell align="center" >Organization</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.find_case.others.length > 0 ?
                                this.state.find_case.others.map((row) => (
                                  <TableRow >
                                    <TableCell align="left"> {row.name}</TableCell>
                                    <TableCell align="left"> {row.organization}</TableCell>
                                  </TableRow>
                                ))
                                :
                                <TableRow >
                                  <TableCell align="center" colSpan={2} style={{ fontSize: "12px", fontStyle: "italic" }}> No data to display</TableCell>
                                </TableRow>
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </SimpleCard>
                    </Grid>
                  </Grid>

                </div>
              </Dialog>

              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={this.state.snakebar}
                autoHideDuration={5000}
                onClose={() => this.setState({
                  snakebar: false,
                })}
              >
                <Alert
                  onClose={() => this.setState({
                    snakebar: false,
                  })}
                  severity={this.state.severity}
                >
                  {this.state.message}
                </Alert>
              </Snackbar>
            </div>
          </Paper >
        </div >
      </div >
    );
  }

}

export default Track;

