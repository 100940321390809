import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const ViewCases = MatxLoadable({
  loader: () => import("./ViewCases/ViewCases")
})

const MarkedasCan = MatxLoadable({
  loader: () => import("./MarkedasCan/MarkedCan")
})
const MarkedasCannot = MatxLoadable({
  loader: () => import("./MarkedasCannot/MarkedCannot")
})

const FocalPoints = MatxLoadable({
  loader: () => import("./FocalPoints/Focalpoint")
})

const NewCasesTab = MatxLoadable({
  loader: () => import("./NewCases/TabBar")
})

const CanCaseTab = MatxLoadable({
  loader: () => import("./MarkedasCan/TabBar")
})

const CanNotCaseTab = MatxLoadable({
  loader: () => import("./MarkedasCannot/TabBar")
})

const FocalPointTab = MatxLoadable({
  loader: () => import("./FocalPoints/TabBar")
})

const MACaseRoutes = [
  {
    path: "/ma/allcases",
    component: ViewCases,
    auth: authRoles.MaAllCases
  }, 

  {
    path: "/ma_case/markedcancases",
    component: MarkedasCan,
    auth: authRoles.MaMarkedasCan
  },
  {
    path: "/ma_case/markedcannotcases",
    component: MarkedasCannot,
    auth: authRoles.MaMarkedasCannot
  },

  {
    path: "/ma_case/focalpointcases",
    component: FocalPoints,
    auth: authRoles.MaFocalPoints
  },
  {
    path: "/ma_case/new_case/:id",
    component: NewCasesTab,
    auth: authRoles.MaNewCaseDetails
  },
  {
    path: "/ma_case/can_case/:id",
    component: CanCaseTab,
    auth: authRoles.MaFocalPoints
  },
  {
    path: "/ma_case/cannot_case/:id",
    component: CanNotCaseTab,
    auth: authRoles.MaFocalPoints
  },
  {
    path: "/ma_case/focalpoint_case/:id",
    component: FocalPointTab,
    auth: authRoles.MaFocalPoints
  },
];

export default MACaseRoutes;