import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles"
import Focalpoint from "./Focalpoint";

const focalpoint = MatxLoadable({
  loader: () => import("./Focalpoint")
})

const focalpointRoute = [
  {
    path: "/datasetup/focalpoint",
    component: Focalpoint,
    auth: authRoles.datasetup
  }
];

export default focalpointRoute ;