import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Schedule = MatxLoadable({
    loader: () =>
        import ("./Schedule")
})

const AllScheduleOfficer = MatxLoadable({
    loader: () =>
        import ("./AllView/Officer/schedule/TabBar")
})

const AllVisitsOfficer = MatxLoadable({
    loader: () =>
        import ("./AllView/Officer/visit/TabBar")
})


const AllScheduleScheduleOfficer = MatxLoadable({
    loader: () =>
        import ("./AllView/SchedulingOfficer/schedule/TabBar")
})

const AllVisits = MatxLoadable({
    loader: () =>
        import ("./AllView/SchedulingOfficer/visit/TabBar")
})

const ScheduleRoutes = [{
        path: "/newschedule",
        component: Schedule,
        auth: authRoles.schedule
    },
    {
        path: "/myschedule",
        component: AllScheduleOfficer,
        auth: authRoles.all
    },
    {
        path: "/mysvisits",
        component: AllVisitsOfficer,
        auth: authRoles.all
    },
    {
        path: "/allschedule",
        component: AllScheduleScheduleOfficer,
        auth: authRoles.schedule
    },
    {
        path: "/schedule/allvisits",
        component: AllVisits,
        auth: authRoles.schedule
    },
];

export default ScheduleRoutes;