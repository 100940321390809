import React, { Component } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { SimpleCard } from "matx";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import Switch from '@material-ui/core/Switch';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { withStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import FocalPointOfficerService from './../../../services/FocalPointOfficerService';
import FocalPointService from './../../../services/FocalPointService';


const styles = theme => ({
    cssLabel: {
        color: 'red'
    },

    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `${theme.palette.primary.main} !important`,
        }
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'blue !important'
    },

});
class ListfocalOfficer extends Component {
    state = {
        officeeditmodel: false,
        FocalPointOfficer: [],
        deleteconform: false,
        snakebar: false,
        message: "",
        severity: "success",
        id: '',
        focalpointdepartments: [],
        formdata: { name: "", title: "", designation: "", email: "", land_no: "", mobile_no: "", status: "", focal_point_department_id: null }

    };

    componentDidMount() {
        this.loadFocalPointOfficer();
        this.loadFocalPoint();
    }

    loadFocalPointOfficer() {//Loading all FocalPointOfficer
        FocalPointOfficerService.getallFocalPointOfficer().then((data) => {
            console.log(data);
            this.setState({ FocalPointOfficer: data })
        });
    }
    loadFocalPoint() {//Loading all FocalPoint
        FocalPointService.getallFocalPoint().then((data) => {
            this.setState({ focalpointdepartments: data })
        });
    }

    handleDialogClose() {//For handle edit dialog
        this.setState({ officeeditmodel: false })
    }

    handleClose = (event, reason) => {//For handle alert visibility
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snakebar: false })
    };


    handleopen(data) {//For open edit selected focalpointofficers

        this.setState({
            id: data.id,
            formdata: { name: data.name, title: data.title, designation: data.designation, email: data.email, land_no: data.land_no, mobile_no: data.mobile_no, status: data.status, focal_point_department_id: data.focal_point_department_id },
        });

        this.setState({ officeeditmodel: true })
    }


    handleChange = event => {//For Set edited form data
        event.persist();
        var data = this.state.formdata;

        if ([event.target.name] == "o_name") {
            data['o_name'] = event.target.value;
            this.setState({
                formdata: data
            });
        } else {
            data[event.target.name] = event.target.value;
            this.setState({
                formdata: data
            });
        }

    };

    setdropdowndata(key, value) {// Set value of dropdown
        var data = this.state.formdata;
        if (value == null) {
            data[key] = "";
            this.setState({ formdata: data });
        } else {
            data[key] = value.title;
            this.setState({ formdata: data });
        }
    }

    getselectedvalue() {//for add dropdown default value
        var a = this.state.focalpointdepartments.filter(x => x.id === this.state.formdata.focal_point_department_id)
        return a[0];
    }

    handleFormSubmit = event => {//For submit edited focalpointofficers
        let id = this.state.id;
        let data = this.state.formdata;

        FocalPointOfficerService.editFocalPointOfficer(id, data).then(res => {
            if (res.status == 200) {
                this.setState({ message: res.data.message, snakebar: true, severity: "success", officeeditmodel: false })
                setTimeout(() => {
                        this.componentDidMount()
                    }, 2500);
            } else {
                this.setState({ message: "Can not edit Officer", snakebar: true, severity: "error" })
            }
        });
    }



    delete(id) {//For delete selected  FocalPointOfficer
        FocalPointOfficerService.deleteFocalPointOfficer(id).then(res => {
            if (res.status == 200) {
                this.setState({ message: res.data.message, snakebar: true, severity: "success" })
                setTimeout(() => {
                        this.componentDidMount()
                    }, 2500);
            } else {
                this.setState({ message: "Can not delete  Officer", snakebar: true, severity: "error" })
            }
        })

    }
    changeStatus = (data) => {//For delete selected category
        let id = data.id;
        if (data.status === "Active") {
            FocalPointOfficerService.deactiveFocalPointOfficer(data.id).then(res => {
                if (res.status == 200) {
                    this.setState({ message: res.data.message, snakebar: true, severity: "success", officeeditmodel: false })
                    setTimeout(() => {
                        this.componentDidMount()
                    }, 2500);
                } else {
                    this.setState({ message: "Can not edit  Officer", snakebar: true, severity: "error" })
                }
            });
        } else {


            data["status"] = "Active";
            FocalPointOfficerService.editFocalPointOfficer(id, data).then(res => {
                if (res.status == 200) {
                    this.setState({ message: res.data.message, snakebar: true, severity: "success", officeeditmodel: false })
                    setTimeout(() => {
                        this.componentDidMount()
                    }, 2500);
                } else {
                    this.setState({ message: "Can not edit  Officer", snakebar: true, severity: "error" })
                }
            });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <SimpleCard title="Focal Point Contact Officer List">
                    <TableContainer component={Paper}>
                        <Table style={{ minWidth: 700, tableLayout: "auto" }} stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Department</TableCell>
                                    <TableCell align="center">Name</TableCell>
                                    <TableCell align="center">Title</TableCell>
                                    <TableCell align="center">Designation</TableCell>
                                    <TableCell align="center">Email</TableCell>
                                    <TableCell align="center">Landline number</TableCell>
                                    <TableCell align="center">Mobile Number</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.FocalPointOfficer.map((row) => (
                                    <TableRow key={row.id}>

                                        <TableCell align="center">{row.focalpointdepartment !== null ? row.focalpointdepartment.name : ""}</TableCell>
                                        <TableCell align="center">{row.name}</TableCell>
                                        <TableCell align="center">{row.title}</TableCell>
                                        <TableCell align="center">{row.designation}</TableCell>
                                        <TableCell align="center">{row.email}</TableCell>
                                        <TableCell align="center">{row.land_no}</TableCell>
                                        <TableCell align="center">{row.mobile_no}</TableCell>
                                        <TableCell align="center">{row.status}</TableCell>
                                        <TableCell align="center" style={{ minWidth: 100 }}>
                                            <Tooltip title="Edit" arrow placement="right">
                                                <IconButton aria-label="edit" onClick={() => { this.handleopen(row); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={row.status === "Active" ? "Delete" : "Recover"} arrow placement="right">
                                                <Switch
                                                    checked={row.status === "Active" ? true : false}
                                                    onClick={() => { this.changeStatus(row); }}
                                                    color="primary"
                                                    size="small"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </Tooltip>
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                    <Dialog fullWidth={true} onClose={() => { this.handleDialogClose(); }} aria-labelledby="customized-dialog-title" open={this.state.officeeditmodel}>
                        <div className="pb-30 px-30 w-90">
                            <DialogTitle id="customized-dialog-title">
                                Edit officer
                            </DialogTitle>
                            <Grid>
                                <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                                    <Grid container spacing={3} >
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                className="mb-24 w-100"
                                                options={this.state.focalpointdepartments}
                                                getOptionLabel={(option) => option.name}
                                                size='small'
                                                value={this.getselectedvalue()}
                                                name="focal_point_department_id"
                                                onChange={(event, value) => this.setdropdowndata("focal_point_department_id", value)}
                                                renderInput={(params) => <TextField {...params} label="Department" variant="outlined" />}
                                            />
                                            <TextValidator
                                                className="mb-24 w-100"
                                                variant="outlined"
                                                label="Officer Name"
                                                onChange={this.handleChange}
                                                size='small'
                                                name="name"
                                                value={this.state.formdata.name}
                                                error
                                                helperText="Required Field "
                                                validators={["required"]}
                                                errorMessages={[
                                                    "this field is required"
                                                ]}
                                            />
                                            <TextValidator
                                                className="mb-24 w-100"
                                                variant="outlined"
                                                label="Title"
                                                onChange={this.handleChange}
                                                size='small'
                                                name="title"
                                                value={this.state.formdata.title}
                                                error
                                                helperText="Required Field "
                                                validators={["required"]}
                                                errorMessages={[
                                                    "this field is required"
                                                ]}
                                            />
                                            <TextValidator
                                                className="mb-24 w-100"
                                                variant="outlined"
                                                label="Designation"
                                                onChange={this.handleChange}
                                                size='small'
                                                name="designation"
                                                value={this.state.formdata.designation}
                                                error
                                                helperText="Required Field "
                                                validators={["required"]}
                                                errorMessages={[
                                                    "this field is required"
                                                ]}
                                            />

                                        </Grid>
                                        <Grid item xs={12} sm={6}>

                                            <TextValidator
                                                className="mb-24 w-100"
                                                variant="outlined"
                                                label="Email"
                                                onChange={this.handleChange}
                                                size='small'
                                                type="email"
                                                name="email"
                                                value={this.state.formdata.email}
                                                // validators={["required"]}
                                                errorMessages={[
                                                    "this field is required"
                                                ]}
                                            />
                                            <TextValidator
                                                className="mb-24 w-100"
                                                variant="outlined"
                                                label="Land line Number"
                                                onChange={this.handleChange}
                                                size='small'
                                                name="land_no"
                                                value={this.state.formdata.land_no}
                                                // validators={["required"]}
                                                errorMessages={[
                                                    "this field is required"
                                                ]}
                                            />
                                            <TextValidator
                                                className="mb-24 w-100"
                                                variant="outlined"
                                                label="Mobile Number"
                                                onChange={this.handleChange}
                                                size='small'
                                                name="mobile_no"
                                                value={this.state.formdata.mobile_no}
                                                // validators={["required"]}
                                                errorMessages={[
                                                    "this field is required"
                                                ]}
                                            />

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button variant="contained" type="submit" color="primary" style={{ margin: 15 }}>Save</Button>
                                    </Grid>


                                </ValidatorForm>
                            </Grid>
                        </div>
                    </Dialog>
                </SimpleCard>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.snakebar} autoHideDuration={1000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity={this.state.severity}>
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        );
    };

}

ListfocalOfficer.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(ListfocalOfficer);