import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import { makeStyles } from '@material-ui/core/styles';
import { SimpleCard } from "matx";
import { Typography } from '@material-ui/core';
import moment from "moment";

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';


import Service from '../../../services/ExternalRequestService';

const useStyles = makeStyles({
  conecter: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    width: 250
  }
});


class TimeLine extends Component {


  state = {
    time_line: null
  };

  componentDidMount() {
    this.get_time_line()

  }

  get_time_line() {
    let temp = []
    Service.get_timeline(this.props.case_id).then((res) => {
      if (res === 500) {
        this.get_time_line()
      } else {
        let data = res
        for (let i = 0; i < data.length; i++) {

          let days = 0
          if (i + 1 != data.length) {
            days = this.get_days(
              data[i].created_at, data[i + 1].created_at
            )
          }
          temp.push({
            id: i + 1,
            text: data[i].action,
            date: data[i].created_at,
            days: days
          })

        }
        this.setState({ time_line: this.props.time_line })
      }

      var myDiv = document.getElementById("timeline_horizontal");

      setTimeout(function () {
        myDiv.scrollLeft = myDiv.scrollWidth;
      }, 500);
    });
  }

  // format and return note date
  return_date(date) {
    return moment(date).format("YYYY-MM-DD")
  }

  get_days(date1, date2) {

    var d1 = moment(date1);
    var d2 = moment(date2);

    var count = 0
    var hours = 0
    var minutes = 0
    var seconds = 0
    var days = d2.diff(d1, 'days')

    if (days != 0) {
      count = days > 1 ? days + " Days" : days + " Day"
    } else {
      hours = d2.diff(d1, 'hours')
      if (hours != 0) {
        count = hours > 1 ? hours + " Hours" : hours + " Hour"
      } else {
        minutes = d2.diff(d1, 'minutes')
        if (minutes != 0) {
          count = minutes > 1 ? minutes + " Minutes" : minutes + " Minute"
        } else {
          seconds = d2.diff(d1, 'seconds')
          count = seconds > 1 ? seconds + " Seconds" : seconds + " Second"
        }

      }
    }


    return count

  }


  render() {

    return (
      <div >
        {this.state.time_line ?
          <SimpleCard  >
            <div id="timeline_horizontal" style={{ width: "100%", height: "auto", overflow: "auto", overflowX: "scroll", overflowY: "hidden", alignItems: 'center' }} >
              <Timeline style={{ height: 150, width: 200, transform: "rotate(-90deg)" }} align="alternate" >
                {this.state.time_line.map((row) =>
                  row.id % 2 != 0 ?
                    <TimelineItem key={row.id} style={{ minHeight: "100px" }}>
                      <TimelineOppositeContent style={{ display: "flex", flexDirection: "row", justifyContent: "center", paddingLeft: 0, paddingTop: 0, paddingBottom: 0 }} >
                        <Typography style={{ fontSize: 12, width: 80, marginTop: -12, marginRight: 10, transform: "rotate(90deg)" }}>
                          {row.text}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color="primary" style={{ margin: 0 }}> </TimelineDot>
                        <TimelineConnector style={{ minHeight: "98px", display: row.id == this.state.time_line.length ? "none" : "block" }} />
                      </TimelineSeparator>
                      <TimelineOppositeContent style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 0 }}>
                        <Typography style={{ marginBottom: 10, fontSize: 11, marginLeft: 5 }}>
                          {this.return_date(row.date)}
                        </Typography>
                        <Typography style={{ fontSize: 12, height: 20, width: 80, marginLeft: -30, marginTop: 20, textAlign: "center", transform: "rotate(90deg)", display: row.id == this.state.time_line.length ? "none" : "block" }}>
                          {row.days}
                        </Typography>
                      </TimelineOppositeContent>
                    </TimelineItem>
                    :
                    <TimelineItem key={row.id} style={{ minHeight: "100px" }}>
                      <TimelineOppositeContent style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 0 }}>
                        <Typography style={{ marginBottom: 10, fontSize: 11, marginLeft: 5 }}>
                          {this.return_date(row.date)}
                        </Typography>
                        <Typography style={{ fontSize: 12, height: 20, width: 80, marginLeft: -30, marginTop: 20, textAlign: "center", transform: "rotate(90deg)", display: row.id == this.state.time_line.length ? "none" : "block" }}>
                          {row.days}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color="primary" style={{ margin: 0 }}> </TimelineDot>
                        <TimelineConnector style={{ minHeight: "98px", display: row.id == this.state.time_line.length ? "none" : "block" }} />
                      </TimelineSeparator >
                      <TimelineOppositeContent style={{ display: "flex", flexDirection: "row", justifyContent: "center", paddingLeft: 0, paddingTop: 0, paddingBottom: 0 }} >
                        <Typography style={{ fontSize: 12, width: 80, marginTop: -12, marginRight: 10, transform: "rotate(90deg)" }}>
                          {row.text}
                        </Typography>
                      </TimelineOppositeContent>
                    </TimelineItem>
                )}
              </Timeline>
            </div>
          </SimpleCard>
          : null}
      </div>
    );
  }
}

export default withStyles({}, { withTheme: true })(TimeLine);
