import axios from 'axios';
import * as appconst from './../appconst';
import localStorageService from "./localStorageService";

class FocalPointService {
  ls = window.localStorage

  getallFocalPoint = async () => {//Get All FocalPoint
    const token = await localStorageService.getItem("auth_user");
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.token}`
      }
    };
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.FOCAL_POINTS, headers)
        .then(res => {

          return resolve(res.data.FocalPointDepartment);
        })
        .catch((error) => {
          console.log("error", error);
          return resolve(error.response.status);
        })
    });
    return await a;
  }

  getFocalPointbyId = async (id) => {//Get FocalPoint by id
    const token = await localStorageService.getItem("auth_user");
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.token}`
      }
    };
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.FOCAL_POINTS + "/" + id, headers)
        .then(res => {
          return resolve(res);
        })
        .catch((error) => {
          console.log("error", error);
          return resolve(error.response.status);
        })
    });
    return await a;
  }

  editFocalPoint = async (id, data) => {//Edit FocalPoint by id
    const a = new Promise((resolve, reject) => {
      axios.put(appconst.FOCAL_POINTS + "/" + id, data).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  deleteFocalPoint = async (id) => {//Delete FocalPoint by id
    const a = new Promise((resolve, reject) => {
      axios.delete(appconst.FOCAL_POINTS + "/" + id).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  deactiveFocalPoint = async (id) => {//Delete FocalPoint
    const a = new Promise((resolve, reject) => {
      axios.put(appconst.FOCAL_POINTS + "/delete/" + id).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  createFocalPoint = async (data) => {//Create new FocalPoint
    const a = new Promise((resolve, reject) => {
      axios.post(appconst.FOCAL_POINTS, data).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }


  getAllFocalPointcases = async (params) => {//Get All cannot cases
    const token = await localStorageService.getItem("auth_user");
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.token}`
      },
      params: params
    };
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.GET_FOCALPOINTCASES, headers)
        .then(res => {
          return resolve(res.data.Case);
        })
        .catch((error) => {
          console.log("error", error);
          return resolve(error.response.status);
        })
    });
    return await a;
  }

  getAllFocalPointcasesbycaseno = async (params) => {//Get All cannot cases
    const token = await localStorageService.getItem("auth_user");
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.token}`
      },
      params: params
    };
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.GET_FOCALPOINTCASESBYCASENO, headers)
        .then(res => {
          return resolve(res.data.Case);
        })
        .catch((error) => {
          console.log("error", error);
          return resolve(error.response.status);
        })
    });
    return await a;
  }

  getAllFocalPointWithPaginations = async (params, pagenumber) => {
    const token = await localStorageService.getItem("auth_user");
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.token}`
      },
      params: params
    };
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.GET_FOCALPOINTCASES + "?page=" + pagenumber, headers)
        .then(res => {
          return resolve(res.data.Case);
        })
        .catch((error) => {
          console.log("error", error);
          return resolve(error.response.status);
        })
    });
    return await a;
  }

  getAllfocalpointsdepartmnets = async () => {
    const token = await localStorageService.getItem("auth_user");
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.token}`
      }
    };
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.GET_FOCALPOINTDEPARTMENTS, headers)
        .then(res => {
          return resolve(res.data.focal_point);
        })
        .catch((error) => {
          console.log("error", error);
          return resolve(error.response.status);
        })
    });
    return await a;
  }
  getAllFocalPointReport = async (params) => {
    const token = await localStorageService.getItem("auth_user");
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.token}`
      },
      params: params
    };
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.GET_FOCALPOINTCASES_REPORT, headers)
        .then(res => {
          return resolve(res.data.Case);
        })
        .catch((error) => {
          console.log("error", error);
          return resolve(error.response.status);
        })
    });
    return await a;
  }

}

export default new FocalPointService();
