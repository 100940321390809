import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const Summon_types = MatxLoadable({
  loader: () => import("./Summon_types")
})

const Summon_typesRoutes = [
  {
    path: "/datasetup/summon_types",
    component: Summon_types,
    auth: authRoles.datasetup
  }
];

export default Summon_typesRoutes;