import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/styles";
import Focalpointdepartment from "./Focalpoindepartment";
import Listfocalpoint from "./Listfocalpoint";
import ListfocalOfficer from "./ListFocalOfficer";
import FocalpointOfficer from "./FocalpointOfficer";

class Focalpoint extends Component {
  state = {};

  render() {
    let { theme } = this.props;

    return (
      <Fragment>
         <div className="pb-10 pt-30 px-30 ">
          <Listfocalpoint/>
        </div>
          <div className="pb-10 pt-30 px-30 ">
          <Focalpointdepartment/>
        </div>
        <div className="pb-10 pt-30 px-30 ">
          <ListfocalOfficer/>
        </div>
        <div className="pb-10 pt-30 px-30 ">
          <FocalpointOfficer/>
        </div>

        
        
      </Fragment>
    );
  }
}



export default withStyles({}, { withTheme: true })(Focalpoint);
