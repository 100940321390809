import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const Office = MatxLoadable({
  loader: () => import("./Office")
})

const RegionalOfficeRoutes = [
  {
    path: "/datasetup/regionaloffice",
    component: Office,
    auth: authRoles.datasetup
  }
];

export default RegionalOfficeRoutes;
