import axios from 'axios';
import * as appconst from './../appconst';
import localStorageService from "./localStorageService";

class OrganizationService {

    getallorganization = async() => { //Get All organizations
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            }
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.ORGANIZATION, headers)
                .then(res => {
                    return resolve(res.data.Organizations);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getrepondentorganization = async() => { //Get All organizations
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            }
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.GET_RESPONDENT_ORGANIZATIONS, headers)
                .then(res => {
                    return resolve(res.data.Organizations);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    createOrganization = async(data) => { //Create new organization
        const a = new Promise((resolve, reject) => {
            axios.post(appconst.ORGANIZATION, data).then(res => {
                    console.log(res)
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    getOrganizationbyId = async(id) => { //Get Organization by id
        const token = await localStorageService.getItem("auth_user");
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token.token}`
            }
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.ORGANIZATION + "/" + id, headers)
                .then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    editOrganization = async(id, data) => { //Edit Organization by id
        const a = new Promise((resolve, reject) => {
            axios.put(appconst.ORGANIZATION + "/" + id, data).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    deleteOrganizationn = async(id) => { //Delete Organization by id
        const a = new Promise((resolve, reject) => {
            axios.delete(appconst.ORGANIZATION + "/" + id).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }

    deactiveOrganization = async(id) => { //Delete ReferalPoint
        const a = new Promise((resolve, reject) => {
            axios.put(appconst.ORGANIZATION + "/delete/" + id).then(res => {
                    return resolve(res);
                })
                .catch((error) => {
                    console.log("error", error);
                })
        });
        return await a;
    }


}
export default new OrganizationService();