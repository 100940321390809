import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const SchedulView = MatxLoadable({
    loader: () =>
        import ("./TabBar")
})

const ScheduleRoutes = [{
    path: "/officer/visit/:id",
    component: SchedulView,
    auth: authRoles.OfficerViewCases
}, ];

export default ScheduleRoutes;