import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const File = MatxLoadable({
    loader: () =>
        import ("./File")
})

const FileRoutes = [{
    path: "/documents",
    component: File,
    auth: authRoles.all
}, ];

export default FileRoutes;