import { MatxLoadable } from "matx";
import { authRoles } from "../../../../auth/authRoles";

const FocalPointCase = MatxLoadable({
  loader: () => import("./FocalPointCase")
})

const FocalPointCaseRoutes = [
  {
    path: "/FocalPointCase/Director/:id",
    component: FocalPointCase,
    auth: authRoles.directorFocalPointCase
  }
];

export default FocalPointCaseRoutes;