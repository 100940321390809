//for define endpoints 

// export const KEYCLOAK = "http://localhost:8080/";
// export const CLIENT_ID = "client1";
// export const ENDPOINT = "http://127.0.0.1:8000/api/";
// export const CASEDOCUMENT = "http://127.0.0.1:8000/Uploads/Cases/";
// export const PROFILEPICTURE = "http://127.0.0.1:8000/Images/User/";
// export const OLDCASEDOCUMENT = "http://127.0.0.1:8000/Uploads/OldCases/";
// export const DOCUMENTLIBRARY = "http://hrcbackend.test/Uploads/Library/";
// export const LETTERHEAD = "http://127.0.0.1:8000/Uploads/Office/";
// export const VISIT_UPLOADS = "http://127.0.0.1:8000/Uploads/Visit/Evidance/";

// // https
export const KEYCLOAK = "https://hurin.hrcsl.lk/";
export const CLIENT_ID = "client1";
export const ENDPOINT = "https://hurin.hrcsl.lk/api/";
export const CASEDOCUMENT = "https://hurin.hrcsl.lk/Uploads/Cases/";
export const PROFILEPICTURE = "https://hurin.hrcsl.lk/Images/User/";
export const OLDCASEDOCUMENT = "https://hurin.hrcsl.lk/Uploads/OldCases/";
export const DOCUMENTLIBRARY = "https://hurin.hrcsl.lk/Uploads/Library/";
export const LETTERHEAD = "https://hurin.hrcsl.lk/Uploads/Office/";
export const VISIT_UPLOADS = "https://hurin.hrcsl.lk/Uploads/Visit/Evidance/";

//Testing
// export const KEYCLOAK = "https://hurin.hrcsl.lk/";
// export const CLIENT_ID = "client1";
// export const ENDPOINT = "http://20.42.94.23/api/";
// export const CASEDOCUMENT = "http://20.42.94.23/Uploads/Cases/";
// export const PROFILEPICTURE = "http://20.42.94.23/Images/User/";
// export const OLDCASEDOCUMENT = "http://20.42.94.23/Uploads/OldCases/";
// export const DOCUMENTLIBRARY = "http://20.42.94.23/Uploads/Library/";
// export const LETTERHEAD = "http://20.42.94.23/Uploads/Office/";
// export const VISIT_UPLOADS = "http://20.42.94.23/Uploads/Visit/Evidance/";

// off line

// export const KEYCLOAK = "http://192.168.100.77:8086/";
// export const CLIENT_ID = "client1";
// export const ENDPOINT = "http://192.168.100.77:8086/api/";
// export const CASEDOCUMENT = "http://192.168.100.77:8086/Uploads/Cases/";
// export const PROFILEPICTURE = "http://192.168.100.77:8086/Images/User/";
// export const OLDCASEDOCUMENT = "http://192.168.100.77:8086/Uploads/OldCases/";
// export const DOCUMENTLIBRARY = "http://192.168.100.77:8086/Uploads/Library/";
// export const LETTERHEAD = "http://192.168.100.77:8086/Uploads/Office/";
// export const VISIT_UPLOADS = "http://192.168.100.77:8086/Uploads/Visit/Evidance/";



// server
// export const KEYCLOAK = "http://3.14.13.127:8080/";
// export const CLIENT_ID = "client1";
// export const ENDPOINT = "http://ec2-3-14-13-127.us-east-2.compute.amazonaws.com/api/";
// export const PROFILEPICTURE = "http://ec2-3-14-13-127.us-east-2.compute.amazonaws.com/Images/User/";
// export const CASEDOCUMENT = "http://ec2-3-14-13-127.us-east-2.compute.amazonaws.com/Uploads/Cases/";
// export const OLDCASEDOCUMENT = "http://ec2-3-14-13-127.us-east-2.compute.amazonaws.com/Uploads/OldCases/";
// export const DOCUMENTLIBRARY = "http://ec2-3-14-13-127.us-east-2.compute.amazonaws.com/Uploads/Library/";
// export const LETTERHEAD = "http://ec2-3-14-13-127.us-east-2.compute.amazonaws.com/Uploads/Office/";
// export const VISIT_UPLOADS = "http://ec2-3-14-13-127.us-east-2.compute.amazonaws.com/Uploads/Visit/Evidance/";




//export const ENDPOINT = "http://localhost:4001/";
//export const ENDPOINT = "http://127.0.0.1:8000/api/";
//export const ENDPOINT = "http://hurin.hrcsl.lk/api/";

//------------------------------------------------------------------
//Auth routes
export const LOGIN_URL = KEYCLOAK + "auth/realms/HRCRealm/protocol/openid-connect/token";
export const USERINFOR_URL = KEYCLOAK + "auth/realms/HRCRealm/protocol/openid-connect/userinfo";

//User Roles routes
export const GET_ROLES = KEYCLOAK + "auth/admin/realms/HRCRealm/roles";

//User Create routes
export const USER_KEYCLOAK = KEYCLOAK + "auth/admin/realms/HRCRealm/users";

export const REFRESH_TOKEN = KEYCLOAK + "auth/realms/HRCRealm/protocol/openid-connect/token";

//------------------------------------------------------------------

//App routes

//Regional Office routes
export const GET_REGIONALOFFICE = ENDPOINT + "offices";

//Regional Department routes
export const DEPARTMENT = ENDPOINT + "departments";

//Cannot reason routes
export const CANNOT_REASON = ENDPOINT + "cannotreasons";

//Summon types create routes
export const SUMMON_TYPES = ENDPOINT + "summontypes";

//Call types create routes
export const CALL_TYPES = ENDPOINT + "calltypes";

//Meeting types create routes
export const MEETINGROOMS = ENDPOINT + "meetingrooms";

//Designation routes
export const DESIGNATIONS = ENDPOINT + "designations";

//Focal points routes
export const FOCAL_POINTS = ENDPOINT + "focalpointdepartments";

//Focal point officers routes
export const FOCAL_POINT_OFFICERS = ENDPOINT + "focalpointofficers";

//Referal points routes
export const REFERAL_POINTS = ENDPOINT + "referalpoints";

//Articles routes
export const ARTICLES = ENDPOINT + "articles";

//Unit routes
export const UNIT = ENDPOINT + "units";






export const ORGANIZATION = ENDPOINT + "organizations";

//Category and Subcategory routes
export const GET_ALLCATEGORIES = ENDPOINT + "categories";
export const GET_SUBCATEGORIES = ENDPOINT + "subcategories/category/";
export const GET_SUBCATEGORIES_ALL = ENDPOINT + "subcategories";
export const CREATE_SUBCATEGORY = ENDPOINT + "subcategories";
export const DEACTIVE_CATEGORY = ENDPOINT + "categories/delete/";
export const ACTIVE_CATEGORY = ENDPOINT + "categories/";
export const DEACTIVE_SUBCATEGORY = ENDPOINT + "subcategories/delete/";
export const ACTIVE_SUBCATEGORY = ENDPOINT + "subcategories/";

//Users Route
export const USERS = ENDPOINT + "users";
export const CHANGE_USER_STATUS = ENDPOINT + "users/status/";
export const UPDATE_USER = ENDPOINT + "users/";
export const UPDATE_PROFILEPICTURE = ENDPOINT + "users/profilepicture/";
export const ALL_USERS = ENDPOINT + "users/all";
export const ALL_USERS_BY_TYPE = ENDPOINT + "user/type";

//Director Routes
export const DIRECTOR = ENDPOINT + "director";

//Director new cases
export const GET_ALLNEWCASES = ENDPOINT + "cases/director/filter";
export const GET_ALLNEWCASES_REPORT = ENDPOINT + 'cases/director/filter/report'
export const GET_ALLNEWCASESBYCASENO = ENDPOINT + "cases/director/filter/case_no";
export const GET_ALLNEWCASESBYCASENO_REGISTERD = ENDPOINT + "cases/registerd/case_no";
export const GET_ALLNEWCASES_REGISTERD = ENDPOINT + "cases/registerd/filter";


//Marked as can cases
export const GET_CANCASES = ENDPOINT + "cases/cancases/director/filter"
export const GET_CANCASES_REPORT = ENDPOINT + "cases/cancases/director/filterNopaginations"

export const GET_CANCASESBYCASENO = ENDPOINT + "cases/cancases/director/filter/case_no";

//Marked as cannot cases
export const GET_CANNOTCASES = ENDPOINT + "cases/cannotcases/director/filter"
export const GET_CANNOTCASES_REPORT = ENDPOINT + 'cases/cannotcases/director/filter/report'
export const GET_CANNOTCASESBYCASENO = ENDPOINT + "cases/cannotcases/director/filter/case_no";

//Referred to focal point
export const GET_FOCALPOINTCASES = ENDPOINT + "cases/focalpointcases/director/filter"
export const GET_FOCALPOINTCASES_REPORT = ENDPOINT + "cases/focalpointcases/director/filter/report"
export const GET_FOCALPOINTCASESBYCASENO = ENDPOINT + "cases/focalpointcases/director/filter/case_no";

//assigned officers
export const GET_ASSIGNEDOFFICERS = ENDPOINT + "assignedofficers"
export const GET_All_ASSIGNEDOFFICERS = ENDPOINT + "assignedofficers/all"
export const GET_All_ASSIGNEDOFFICERS_BY_OFFICE = ENDPOINT + "assignedofficers/"
export const GET_ALLNEWCASESFORASSIGNEDOFFICER = ENDPOINT + "officer/cases/new";
export const GET_ALLACCEPTCASESFORASSIGNEDOFFICER = ENDPOINT + "officer/cases/accept";
export const GET_ALLREJECTCASESFORASSIGNEDOFFICER = ENDPOINT + "officer/cases/reject";
export const GET_ALLNEWCASESFORASSIGNEDOFFICERCASENO = ENDPOINT + "officer/cases/new/case_no";
export const GET_ALLACCEPTCASESFORASSIGNEDOFFICERCASENO = ENDPOINT + "officer/cases/accept/case_no";
export const GET_ALLREJECTCASESFORASSIGNEDOFFICERCASENO = ENDPOINT + "officer/cases/reject/case_no";
export const GET_ALLCLOASEDCASESFORASSIGNEDOFFICER = ENDPOINT + "officer/cases/close";
export const GET_ALLCLOSECASESFORASSIGNEDOFFICERCASENO = ENDPOINT + "officer/cases/close/case_no";
//getcannot reasons
export const GET_CANNOTREASONS = ENDPOINT + "cannotreasons"

//get focal points
export const GET_FOCALPOINTDEPARTMENTS = ENDPOINT + "focalpointdepartments"

//MA case routes
export const MA_CASES = ENDPOINT + "ma_case"

//Cases Routes
export const TEMPORYNUMBER = ENDPOINT + "temporyno/cases";
export const RELATED_CASE_BY_COMPLAINER_NIC = ENDPOINT + "relatedcases/complainer/nic/";
export const RELATED_CASE_BY_VICTIM_NIC = ENDPOINT + "relatedcases/victim/nic/";
export const RELATED_CASE_BY_RESPONDENT_NIC = ENDPOINT + "relatedcases/respondent/nic/";

export const RELATED_CASE_BY_COMPLAINER_NAME = ENDPOINT + "relatedcases/complainer/name/";
export const RELATED_CASE_BY_VICTIM_NAME = ENDPOINT + "relatedcases/complainer/name/";
export const RELATED_CASE_BY_RESPONDENT_NAME = ENDPOINT + "relatedcases/complainer/name/";

export const RELATED_CASE_BY_COMPLAINER_ORGANIZATION = ENDPOINT + "relatedcases/complainer/organization/";
export const RELATED_CASE_BY_VICTIM_ORGANIZATION = ENDPOINT + "relatedcases/complainer/organization/";
export const RELATED_CASE_BY_RESPONDENT_ORGANIZATION = ENDPOINT + "relatedcases/complainer/organization/";

export const CASE = ENDPOINT + "cases";
export const GET_CASE_BY_CASEID = ENDPOINT + "cases/";
export const GET_CANDETAILS_BY_CASEID = ENDPOINT + "casescandetails/";
export const ADD_CASE_COMPLAINERS = ENDPOINT + "cases/casecomplainers/";
export const GET_ALLTEMPORARYCASESFILTER = ENDPOINT + "cases/temporrycases/filter";
export const GET_ALLTEMPORARYCASESFILTER_All_OFFICE = ENDPOINT + "cases/temporrycases/alloffices";
export const GET_ALLTEMPORARYCASESFILTERBYCASENO = ENDPOINT + "cases/temporrycases/filter/case_no";
export const GET_ALLTEMPORARYCASESFILTERBYCASENO_All_OFFICE = ENDPOINT + "cases/temporrycases/alloffices/case_no";

//case registration by front desk----------------------
export const GET_CASE_BY_TEMPORYNO = ENDPOINT + "cases/temporyno/";
export const REGISTER_CASE = ENDPOINT + "cases/caseno";
export const UPDATE_CASE_NO = ENDPOINT + "cases/caseno_update/";
export const UNDO_CANNOT_CASE = ENDPOINT + "cases/undo_cannot_case/";

export const CASE_UPLOADS = ENDPOINT + "cases/uploads";
export const CASE_EVIDANCE_UPLOADS = ENDPOINT + "cases/evidance";
export const CREATE_CASE_CLOSE_LETTERS = ENDPOINT + "cases/closeletter";
export const UPDATE_PERSON = ENDPOINT + "casepersons";
export const UPDATE_CASE_PERSON_PRIORITYNO = ENDPOINT + "cases/caseperson/priority_no";
export const GET_ALLCASES = ENDPOINT + "cases/director/all";

//------------------------------------------------------------------
//---------------------Director make can Cannot cases
export const GET_ALLARTICLES = ENDPOINT + "articles";
export const GET_ALLUNITS = ENDPOINT + "units";
export const GET_ASSIGNEDOFFICER = ENDPOINT + "assignedofficers";
export const GET_ASSIGNEDOFFICER_BY_OFFICE = ENDPOINT + "assignedofficers/";
export const GET_ALLCANNOTREASON = ENDPOINT + "cannotreasons";
export const GET_ALLCANNOTREFFARALPOINTS = ENDPOINT + "referalpoints";
export const CREATE_REFFERALPOINT = ENDPOINT + "referalpoints";
export const GET_ALLFOCALPOINTS = ENDPOINT + "focalpointdepartments";
export const CREATE_CANCASE = ENDPOINT + "cases/cancases";
export const CREATE_CANNOTCASE = ENDPOINT + "cases/cannotcases";
export const CREATE_FOCALPOINTCASE = ENDPOINT + "cases/focalpointcases";

//---------------------Officer Case Routes
export const UPDATE_AS_ACCEPTCASE = ENDPOINT + "cases/officer/accept";
export const UPDATE_AS_REJECTCASE = ENDPOINT + "cases/officer/reject";

//---------------------Old Case Routes

export const GET_OLD_INCOMPLETE_CASES = ENDPOINT + "oldcases/incomplete";
export const GET_OLD_CAN_CASES = ENDPOINT + "oldcases/can";
export const GET_OLD_CANNOT_CASES = ENDPOINT + "oldcases/cannot";
export const GET_OLD_INCOMPLETE_CASES_BY_CASE_NO = ENDPOINT + "oldcases/incompletecases/caseno";
export const GET_OLD_CAN_CASES_BY_CASE_NO = ENDPOINT + "oldcases/cancases/caseno";
export const GET_OLD_CANNOT_CASES_BY_CASE_NO = ENDPOINT + "oldcases/cannotcases/caseno";
export const CREATE_OLD_CASES_RECOMMANDATION = ENDPOINT + "oldcases/recommandations";
export const UPDATE_OLD_CASES = ENDPOINT + "oldcases"; //added

export const CANNOT_LETTERS = ENDPOINT + "cases/cannotletters";
export const CANNOT_LETTERS_UPLOADS = ENDPOINT + "cases/cannotletteruploads";
export const UPDATE_CASE_COMPLAINT = ENDPOINT + "cases/casecomplaint";
export const UPDATE_CASE_COMPLAINT_SUMOTTO = ENDPOINT + "cases/sumotto/";

// officer note route
export const CREATE_OFFICER_NOTE = ENDPOINT + "officer_note";
export const GET_ALL_OFFICER_NOTES = ENDPOINT + "officer_note/all/";

// calling route
export const CREATE_CALLING = ENDPOINT + "callings";
export const GET_CASE_CALLINGS = ENDPOINT + "callings/case/";
export const GET_CASE_CALLING_BY_ID = ENDPOINT + "callings/find/";
export const CREATE_CALLING_RESPONCE = ENDPOINT + "callings/recievedreports";
export const GET_CALLING_RESPONCE = ENDPOINT + "callings/case/bydateandtype";
export const DELETE_CALLING = ENDPOINT + "callings/delete/";

// summon calling route 
export const UPLOAD_SUMMON_DOCUMENT = ENDPOINT + "summons/other/uploads/";
export const GET_SUMMON_INOVOLES = ENDPOINT + "summons/users/involves";
export const CREATE_SUMMON_CALLING = ENDPOINT + "summons";
export const GET_SUMMON_CALLINGS = ENDPOINT + "summons/case/";
export const DELETE_SUMMON_CALLINGS = ENDPOINT + "summons/delete/";
export const FIND_SUMMON_CALLING = ENDPOINT + "summons/find/";
export const CANCEL_SUMMON_CALLING = ENDPOINT + "summons/cancel/";


// summon section route
export const GET_ALL_SUMMONS = ENDPOINT + "summons/new/filters";
export const GET_ALL_SUMMONS_CASE_NO = ENDPOINT + "summons/new/caseno";
export const ACCEPT_SUMMONS = ENDPOINT + "summons/accept/";
export const GET_UNAVAILABEL_ROOMS = ENDPOINT + "summons/rooms/availabilities";
export const REJECT_SUMMONS = ENDPOINT + "summons/reject/";
export const SUMMON_LETTERS = ENDPOINT + "summons/letters/";
export const OFFICE_SUMMON_CASE_NUMBER = ENDPOINT + "summons/officer/caseno";
export const OFFICE_SUMMON_FILTER = ENDPOINT + "summons/officer/filter";
export const CHANGE_STENOGRAPHER = ENDPOINT + "summons/chnagesteno/";



// time slot 
export const GET_ALL_TIMES_SLOTS = ENDPOINT + "timeslots";
export const CREATE_TIMES_SLOT = ENDPOINT + "timeslots";
export const UPDATE_TIMES_SLOT = ENDPOINT + "timeslots/";
export const DELETE_TIMES_SLOT = ENDPOINT + "timeslots/delete/";
export const PERMENENT_DELETE_TIMES_SLOT = ENDPOINT + "timeslots/";

// get stenographers
export const GET_ALL_STENOPRAPHERS = ENDPOINT + "stenographers";

// get login user
export const GET_LOGIN_USER = ENDPOINT + "loginusers";

// inquires 
export const CREATE_INQUIRY = ENDPOINT + "inquiries";
export const UPDATE_INQUIRY = ENDPOINT + "inquiries/";
export const UPLOAD_INQUIRY_STATEMENT = ENDPOINT + "inquiries/statements/upload";
export const FIND_INQUIRY = ENDPOINT + "inquiries/id/";



// opinions
export const CREATE_OPINION = ENDPOINT + "opinions";
export const REPLY_OPINION = ENDPOINT + "opinions/";
export const GET_ALL_CASE_OPINIONS = ENDPOINT + "opinion/case/";
export const GET_ALL_USER_OPINIONS = ENDPOINT + "opinion/from/user";
export const GET_ALL_USER_OPINIONS_CASE_NUMBER = ENDPOINT + "opinion/case_no";

// time line

export const GET_TIMELINE_BY_CASE_ID = ENDPOINT + "caseactivities/case/";
// recommendation route
export const CREATE_RECOMMENDATION = ENDPOINT + "recommendations";
export const GET_ALLRECOMMENDATION = ENDPOINT + "recommendations";
export const GET_RECOMMENDATION_BY_CASE_ID = ENDPOINT + "recommendations/case/";
export const GET_RECOMMENDATION_BY_ID = ENDPOINT + "recommendations/";
export const GET_RECOMMENDATION_TO_USER = ENDPOINT + "recommendations/to/";

// reports route
export const CREATE_REPORT = ENDPOINT + "reports";
export const GET_ALLREPORTS = ENDPOINT + "reports";
export const GET_STATUS_OF_CASES_AGAINST_OFFICERS = ENDPOINT + "casestatusagainstofficer";
export const GET_NO_OF_CASES_FOR_YEAR_AGAINST_CATEGORY = ENDPOINT + "casecategoryagainstyear/all";
export const GET_NO_OF_CASES_FOR_YEAR_AGAINST_SUBCATEGORY = ENDPOINT + "subcasecategoryagainstyear/all";
export const GET_NO_OF_PENDING_CASES_BYCAT_AGAINST_OFFICER = ENDPOINT + "casecategoryagainstofficer/all";
export const GET_NO_OF_PENDING_CASES_BY_SUBCAT_AGAINST_OFFICER = ENDPOINT + "subcasecategoryagainstofficer/all";
export const GET_NO_OF_COMPLAINS_RECEIVED_BY_MAIN_CAT = ENDPOINT + "casecategoryagainstcase/all";
export const GET_NO_OF_COMPLAINS_RECEIVED_BY_SUB_CAT = ENDPOINT + "casesubcategoryagainstcase/all";
export const GET_REPORT_BY_CASE_ID = ENDPOINT + "reports/case/";
export const GET_REPORT_BY_ID = ENDPOINT + "reports/";
export const GET_REPORT_TO_USER = ENDPOINT + "reports/to/";

// caseclose route
export const CREATE_CASECLOSE = ENDPOINT + "casecloses";
export const GET_ALLCASECLOSE = ENDPOINT + "casecloses";
export const GET_CASECLOSE_BY_CASE_ID = ENDPOINT + "casecloses/case/";
export const GET_CASECLOSE_BY_ID = ENDPOINT + "casecloses/";


// users from office
export const GET_ALL_USERS_BY_OFFICE = ENDPOINT + "user/office/";
export const GET_ALL_USERS_FROM_OFFICE = ENDPOINT + "user/from/office";


export const GET_ALL_VIEW_CALLINGS = ENDPOINT + "allviews/callings";
export const GET_ALL_VIEW_RECOMMENDATIONS = ENDPOINT + "allviews/recommandations";
export const GET_ALL_VIEW_SUMMONS = ENDPOINT + "allviews/summons";


export const GET_USER_LOG = ENDPOINT + "logactivities/user/";


// dashboard commisioner and director
export const DASHBOARD_CASE_COUNT = ENDPOINT + "dashbord/casecounts";
export const DASHBOARD_CASE_COUNT_NONIMP_RECORD_ROOM = ENDPOINT + "dashbord/count";
export const DASHBOARD_CASE_COUNT_BY_SOURCE = ENDPOINT + "dashbord/casecountsbysource";
export const DASHBOARD_CASE_COUNT_BY_CATEGORY = ENDPOINT + "dashbord/casecountsbycategory";
export const DASHBOARD_CASE_COUNT_BY_OFFICER = ENDPOINT + "dashbord/casecountsbyofficer";
export const DASHBOARD_UPCOMING_INQUIRES = ENDPOINT + "dashbord/upcomminginquiries";
export const DASHBOARD_PENDING_DESSION = ENDPOINT + "dashbord/pendingdecisions";
export const DASHBOARD_CASE_NEED_ATTENTION = ENDPOINT + "dashbord/caseneededattention";

// nonimp
export const DASHBOARD_RECORD_ROOM_APPEALS = ENDPOINT + "dashbord/recordroom/appeal";
export const DASHBOARD_NON_IMP_REMINDERS = ENDPOINT + "dashbord/nonimp/reminders";


// OFFICER DASHBOARD
export const OFFICER_DASHBOARD_CASE_COUNT = ENDPOINT + "dashbord/assignedofficer/casecounts";
export const OFFICER_DASHBOARD_CASE_COUNT_BY_SOURCE = ENDPOINT + "dashbord/assignedofficer/casecountsbysource";
export const OFFICER_DASHBOARD_CASE_COUNT_BY_CATEGORY = ENDPOINT + "dashbord/assignedofficer/casecountsbycategory";
export const OFFICER_DASHBOARD_LATEST_TASKS = ENDPOINT + "dashbord/assignedofficer/latesttask";
export const OFFICER_DASHBOARD_UPCOMING_INQUIRES = ENDPOINT + "dashbord/assignedofficer/upcomminginquiries";
export const OFFICER_DASHBOARD_TASK_COUNT = ENDPOINT + "dashbord/assignedofficer/tasks";


// Visit route
export const CREATE_VISIT_ROUTE = ENDPOINT + "visitroutes";
export const GET_ALL_VISIT_ROUTE = ENDPOINT + "visitroutes";
export const GET_VISIT_ROUTE_BY_OFFICE = ENDPOINT + "visitroutes/";
export const DELETE_VISIT_ROUTE = ENDPOINT + "visitroutes/delete/";
export const UPDATE_VISIT_ROUTE = ENDPOINT + "visitroutes/";

// Route Stop
export const CREATE_VISIT_ROUTE_STOP = ENDPOINT + "visitstops";
export const GET_ALL_VISIT_ROUTE_STOP = ENDPOINT + "visitstops";
export const DELETE_VISIT_ROUTE_STOP = ENDPOINT + "visitstops/delete/";
export const UPDATE_VISIT_ROUTE_STOP = ENDPOINT + "visitstops/";
export const FIND_VISIT_ROUTE_STOP_BY_ROUTE = ENDPOINT + "visitstops/";



// create
export const CREATE_SOP = ENDPOINT + "sop";
export const CREATE_CHECKLIST_RECORD = ENDPOINT + "sop/createchecklist/";
export const CREATE_QUESTIONNAIRE_SECTION = ENDPOINT + "sop/createSection/";
export const CREATE_QUESTIONNAIRE_QUESTION = ENDPOINT + "sop/createquestion/";

// get all
export const GET_ALL_SOP = ENDPOINT + "sop";
//find 
export const FIND_SOP = ENDPOINT + "sop/"

// update
export const UPDATE_SOP = ENDPOINT + "sop/updatesop/";
export const UPDATE_SOP_INSTRUCTION = ENDPOINT + "sop/updateinstruction/";
export const UPDATE_SOP_CHECKLIST = ENDPOINT + "sop/updatechecklist/";
export const UPDATE_SOP_QUESTIONNAIRE = ENDPOINT + "sop/updatesection/";
export const UPDATE_SOP_QUESTIONNAIRE_QUESTION = ENDPOINT + "sop/updatequestion/";

// delete 
export const DELETE_SOP_CHECKLIST_RECORD = ENDPOINT + "sop/deletechecklist/";
export const DELETE_SOP_QUESTIONNAIRE_SECTION = ENDPOINT + "sop/deleteSection/";
export const DELETE_SOP_QUESTIONNAIRE_QUESTION = ENDPOINT + "sop/deleteSectionQuestion/";

// change status
export const DELETE_SOP = ENDPOINT + "sop/changestatus/";


// Document Library
export const CREATE_DOCUMENT = ENDPOINT + "document";
export const GET_ALL_DOCUMENTS = ENDPOINT + "document";


// File Locaation
export const CREATE_FILE_LOCATION = ENDPOINT + "filelocation";
export const GET_ALL_FILES = ENDPOINT + "filelocation";
export const FINDE_CASES_TO_FILE_LOCATION = ENDPOINT + "filelocation/findCases";
export const GET_ALL_OFFICER_TO_FILE_LOCATION = ENDPOINT + "filelocation/officers";


// Schedule
export const CREATE_SCHEDULE = ENDPOINT + "schedule";
export const GET_ALL_SCHEDULES = ENDPOINT + "schedule";
export const APPROVE_SCHEDULE = ENDPOINT + "schedule/approve";
export const GET_ALL_SCHEDULES_TABLE = ENDPOINT + "schedule/table";
export const DELETE_SCHEDULES = ENDPOINT + "schedule/delete/";
export const UPDATE_SCHEDULE = ENDPOINT + "schedule/";
export const GET_ALL_SCHEDULES_VISITS = ENDPOINT + "schedule/visits";
export const SUBMIT_SCHEDULE = ENDPOINT + "schedule/submit";
export const GET_SCHEDULE_CALENDAR = ENDPOINT + "schedule/calendar";


// repoet route
export const GET_ALL_CASE_REPORT = ENDPOINT + "casereports";
export const GET_ALL_CASE_REPORT_CSV = ENDPOINT + "casereports_csv";

export const GET_ALL_CASE_REPORT_BY_CASE_NO = ENDPOINT + "casereportsbycaseno";

// change office route
export const CHANGE_CASE_OFFICE = ENDPOINT + "cases/changeOffice/";



// Non imp
export const GET_ALL_NON_IMP = ENDPOINT + "nonimp";
export const CREATE_REFERENCEE_NO_NON_IMP = ENDPOINT + "nonimp";
export const UPDATE_REFERENCEE_NO_NON_IMP = ENDPOINT + "nonimp/reference/";
export const UPDATE_NO_NON_IMP_RECOMMENDATION = ENDPOINT + "nonimp/";
export const GET_NON_IMP_BACK_REPORTS = ENDPOINT + "nonimp/backreports/";
export const GET_ALL_NON_IMP_RECOMMENDATION = ENDPOINT + "nonimp/recommendation/";
export const CHANGE_NON_IMP_BACK_REPORT_STATUS = ENDPOINT + "nonimp/changestatus";
export const VIEW_NON_IMP_BACK_REPORT = ENDPOINT + "nonimp/find/";
export const GET_ALL_NON_IMP_BACK_REPORTS = ENDPOINT + "nonimp/allbackreports";
export const GET_ALL_NON_IMP_REF_NO = ENDPOINT + "nonimp/all/referenceNumbers/";

export const GET_ALL_RECORD_ROOM_CASES = ENDPOINT + "recordroom/allcases";
export const CREATE_REFERENCEE_NO_RECORD_ROOM = ENDPOINT + "recordroom";
export const UPDATE_REFERENCEE_NO_RECORD_ROOM = ENDPOINT + "recordroom/reference/";
export const GET_RECORD_ROOM_REFERENCE_NO = ENDPOINT + "recordroom/referenceno/";
export const CREATE_RECORD_ROOM_APPEAL = ENDPOINT + "recordroom/create";
export const GET_ALL_RECORD_ROOM_APPEAL_BY_ID = ENDPOINT + "recordroom/getAllAppealById/";
export const CREATE_RECORD_ROOM_APPEAL_DECISION = ENDPOINT + "recordroom/decision";
export const CREATE_RECORD_ROOM_APPEAL_INFORM = ENDPOINT + "recordroom/inform";
export const VIEW_RECORD_ROOM_APPEAL = ENDPOINT + "recordroom/find/";
export const GET_ALL_RECORD_ROOM_APPEALS = ENDPOINT + "recordroom/allAppeals";
export const GET_ALL_RECORD_ROOM_REF_NO = ENDPOINT + "recordroom/all/referenceNumbers/";


export const CREATE_APPEAL = ENDPOINT + "appeal";
export const CREATE_APPEAL_DECISION = ENDPOINT + "appeal/decision";
export const GET_ALL_APPEAL_BY_ID = ENDPOINT + "appeal/allAppeals";
export const CREATE_APPEAL_INFORM = ENDPOINT + "appeal/inform";
export const VIEW_APPEAL = ENDPOINT + "appeal/find/";
export const GET_ALL_APPEAL = ENDPOINT + "appeal";


export const GET_NOTIFICATIONS = ENDPOINT + "notification";
export const READ_NOTIFICATION = ENDPOINT + "notification";
export const READ_ALL_NOTIFICATION = ENDPOINT + "notification/readall";
export const GET_NOTIFICATIONS_COUNT = ENDPOINT + "notification/count";


export const GET_NOTES = ENDPOINT + "note";
export const DELETE_NOTE = ENDPOINT + "note/";
export const CREATE_NOTE = ENDPOINT + "note";
export const GET_NOTES_SUMMON = ENDPOINT + "note/summon";

export const DELETE_CASE = ENDPOINT + "cases/delete/";

// visit
export const FIND_VISIT = ENDPOINT + "visit/find/";
export const GET_VISIT_REPORTS = ENDPOINT + "visit/report/";
export const CREATE_VISIT_REPORT = ENDPOINT + "visit/report/create";
export const DELETE_VISIT_REPORT = ENDPOINT + "visit/report/delete/";


export const GET_SUMMARY_REPORT = ENDPOINT + "summaryreports";
export const GET_RESPONDENT_ORGANIZATIONS = ENDPOINT + "respondentorganization";


export const GET_TIMELINE_BY_CASE_ID_EXTERNAL = ENDPOINT + "timeline_external/";
export const GET_CASE_BY_CASE_NO_EXTERNAL = ENDPOINT + "find_case_external";
export const CHECK_CASE_NO_IS_EXCIST_EXTERNAL = ENDPOINT + "find_case_excist_external";
export const MATCH_MOBILE_EXTERNAL = ENDPOINT + "match_mobile_external";

export const GET_ALLNEWCASESFORASSIGNEDOFFICER_REPORT = ENDPOINT + "officer/cases/new/all";
export const GET_ALLACCEPTCASESFORASSIGNEDOFFICER_REPORT = ENDPOINT + "officer/cases/accept/all";
export const GET_ALLREJECTCASESFORASSIGNEDOFFICER_REPORT = ENDPOINT + "officer/cases/reject/all";
export const GET_ALLCLOASEDCASESFORASSIGNEDOFFICER_REPORT = ENDPOINT + "officer/cases/close/all";

