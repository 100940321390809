import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Sop = MatxLoadable({
    loader: () =>
        import ("./Sop")
})

const SopAll = MatxLoadable({
    loader: () =>
        import ("./SopAll")
})


const ChecklistUpdate = MatxLoadable({
    loader: () =>
        import ("./ChecklistUpdate")
})


const InstructionUpdate = MatxLoadable({
    loader: () =>
        import ("./InstructionUpdate")
})


const QuestionnaireUpdate = MatxLoadable({
    loader: () =>
        import ("./QuestionnaireUpdate")
})



const SopRoutes = [{
        path: "/sop",
        component: Sop,
        auth: authRoles.visit_guide_line
    },
    {
        path: "/allsop",
        component: SopAll,
        auth: authRoles.visit_guide_line
    },
    {
        path: "/sopChecklist/update/:id",
        component: ChecklistUpdate,
        auth: authRoles.visit_guide_line
    },
    {
        path: "/sopInstruction/update/:id",
        component: InstructionUpdate,
        auth: authRoles.visit_guide_line
    },
    {
        path: "/sopQuestionnaire/update/:id",
        component: QuestionnaireUpdate,
        auth: authRoles.visit_guide_line
    }
];

export default SopRoutes;