import React, { Component, useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { SimpleCard, MatxProgressBar } from "matx";

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';

import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import { withStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';

import axios from 'axios';
import * as appVariables from './../../../appVariables';
import UnitService from './../../../services/UnitService';
import Tooltip from '@material-ui/core/Tooltip';
const styles = theme => ({
    cssLabel: {
        color: 'red'
    },

    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `${theme.palette.primary.main} !important`,
        }
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'blue !important'
    },

});
class UnitTable extends Component {
    state = {
        officeeditmodel: false,
        Unit: [],
        deleteconform: false,
        snakebar: false,
        message: "",
        severity: "success",
        id: '',
        formdata: { name: "", status: "" },

    };

    componentDidMount() {
        this.loadunit();

    }

    loadunit() {//Loading all unit 
        UnitService.getallunit().then((data) => {
            console.log("unites", data)
            this.setState({ Unit: data })
        });
    }


    handleDialogClose() {//For handle edit dialog
        this.setState({ officeeditmodel: false })
    }

    handleClose = (event, reason) => {//For handle alert visibility
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snakebar: false })
    };


    handleopen(data) {//For open edit selected unit

        this.setState({
            id: data.id,
            formdata: { name: data.name, status: data.status }
        });

        this.setState({ officeeditmodel: true })
    }


    handleChange = event => {//For Set edited form data
        event.persist();
        var data = this.state.formdata;


        data[event.target.name] = event.target.value;
        this.setState({
            formdata: data
        });


    };

    setdropdowndata(key, value) {// Set value of dropdown
        var data = this.state.formdata;
        if (value == null) {
            data[key] = "";
            this.setState({ formdata: data });
        } else {
            data[key] = value.title;
            this.setState({ formdata: data });
        }
    }

    handleFormSubmit = event => {//For submit edited reginal office
        let id = this.state.id;
        let data = this.state.formdata;

        UnitService.editunit(id, data).then(res => {
            if (res.status == 200) {
                this.setState({ message: res.data.message, snakebar: true, severity: "success", officeeditmodel: false })
                setTimeout(() => {
                        this.componentDidMount()
                    }, 2500);
            } else {
                this.setState({ message: "Can not edit unit", snakebar: true, severity: "error" })
            }
        });
    }
    changeStatus = (data) => {//For delete selected category
        if (data.status === "Active") {
            UnitService.deactiveUnit(data.id).then(res => {
                if (res.status == 200) {
                    this.setState({ message: res.data.message, snakebar: true, severity: "success", officeeditmodel: false })
                    setTimeout(() => {
                        this.componentDidMount()
                    }, 2500);
                } else {
                    this.setState({ message: "Can not edit unit", snakebar: true, severity: "error" })
                }
            });
        } else {
            let id = data.id;

            data["status"] = "Active";
            UnitService.editunit(id, data).then(res => {
                if (res.status == 200) {
                    this.setState({ message: res.data.message, snakebar: true, severity: "success", officeeditmodel: false })
                    setTimeout(() => {
                        this.componentDidMount()
                    }, 2500);
                } else {
                    this.setState({ message: "Can not edit Unit ", snakebar: true, severity: "error" })
                }
            });
        }
    }


    delete(id) {//For delete selected reginal office
        UnitService.deleteunit(id).then(res => {
            if (res.status == 200) {
                this.setState({ message: res.data.message, snakebar: true, severity: "success" })
                setTimeout(() => {
                        this.componentDidMount()
                    }, 2500);
            } else {
                this.setState({ message: "Can not edit unit", snakebar: true, severity: "error" })
            }
        })

    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <SimpleCard title="Unit">
                    <TableContainer component={Paper}>
                        <Table style={{ minWidth: 500 }} stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Unit</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.Unit.map((row) => (
                                    <TableRow key={row.id}>

                                        <TableCell align="center">{row.name}</TableCell>
                                        <TableCell align="center">{row.status}</TableCell>
                                        <TableCell align="center" style={{ minWidth: 100 }}>
                                            <Tooltip title="Edit" arrow placement="right">
                                                <IconButton aria-label="edit" onClick={() => { this.handleopen(row); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={row.status === "Active" ? "Delete" : "Recover"} arrow placement="right">
                                                <Switch
                                                    checked={row.status === "Active" ? true : false}
                                                    onClick={() => { this.changeStatus(row); }}
                                                    color="primary"
                                                    size="small"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </Tooltip>
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                    <Dialog fullWidth={true} onClose={() => { this.handleDialogClose(); }} aria-labelledby="customized-dialog-title" open={this.state.officeeditmodel}>
                        <div className="pb-30 px-30 w-90">
                            <DialogTitle id="customized-dialog-title">
                                Edit Unit
                            </DialogTitle>
                            <ValidatorForm
                                ref="form"
                                onSubmit={this.handleFormSubmit}
                                onError={errors => null}
                            >
                                <Grid container spacing={6}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextValidator
                                            className="mb-16 w-100"
                                            label="Unit"
                                            onChange={this.handleChange}
                                            type="text"
                                            name="name"
                                            size="small"
                                            value={this.state.formdata.name}
                                            error
                                            helperText="Required Field "
                                            variant="outlined"
                                            validators={[
                                                "required",
                                            ]}
                                            errorMessages={["this field is required"]}
                                        />

                                    </Grid>
                                </Grid>
                                <Grid container spacing={6}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Button variant="contained" type="submit" color="primary" style={{ margin: 15 }} size="sm" style={{ float: 'left' }} className="float-left">Save</Button>

                                    </Grid>
                                </Grid>


                            </ValidatorForm>
                        </div>
                    </Dialog>

                </SimpleCard>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.snakebar} autoHideDuration={1000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity={this.state.severity}>
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        );
    };

}

UnitTable.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(UnitTable);
