import React, { Component } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { SimpleCard } from "matx";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FocalPointService from './../../../services/FocalPointService';
import { withStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    cssLabel: {
        color: 'red'
    },

    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `${theme.palette.primary.main} !important`,
        }
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'blue !important'
    },

});
class Listfocalpoint extends Component {
    state = {
        officeeditmodel: false,
        focalpointdepartments: [],
        deleteconform: false,
        snakebar: false,
        message: "",
        severity: "success",
        id: '',
        formdata: { name: "", code: "", email: "", address: "", contact_no: "", fax_no: "", subject_area: "", status: "" },

    };

    componentDidMount() {
        this.loadFocalPoint();
    }

    loadFocalPoint() {//Loading all FocalPoint
        FocalPointService.getallFocalPoint().then((data) => {
            this.setState({ focalpointdepartments: data })
        });
    }


    handleDialogClose() {//For handle edit dialog
        this.setState({ officeeditmodel: false })
    }

    handleClose = (event, reason) => {//For handle alert visibility
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snakebar: false })
    };


    handleopen(data) {//For open edit selected focalpoint

        this.setState({
            id: data.id,
            formdata: { name: data.name, code: data.code, email: data.email, address: data.address, contact_no: data.contact_no, fax_no: data.fax_no, subject_area: data.subject_area, status: data.status },
        });

        this.setState({ officeeditmodel: true })
    }


    handleChange = event => {//For Set edited form data
        event.persist();
        var data = this.state.formdata;

        if ([event.target.name] == "focalpointname") {
            data['name'] = event.target.value;
            this.setState({
                formdata: data
            });
        } else {
            data[event.target.name] = event.target.value;
            this.setState({
                formdata: data
            });
        }

    };

    setdropdowndata(key, value) {// Set value of dropdown
        var data = this.state.formdata;
        if (value == null) {
            data[key] = "";
            this.setState({ formdata: data });
        } else {
            data[key] = value.title;
            this.setState({ formdata: data });
        }
    }


    handleFormSubmit = event => {//For submit edited ReferalPoint
        let id = this.state.id;
        let data = this.state.formdata;

        FocalPointService.editFocalPoint(id, data).then(res => {
            if (res.status == 200) {
                this.setState({ message: res.data.message, snakebar: true, severity: "success", officeeditmodel: false })
                setTimeout(() => {
                        this.componentDidMount()
                    }, 2500);
            } else {
                this.setState({ message: "Can not edit Focal Point", snakebar: true, severity: "error" })
            }
        });
    }



    delete(id) {//For delete selected  FocalPoint
        FocalPointService.deleteFocalPoint(id).then(res => {
            if (res.status == 200) {
                this.setState({ message: res.data.message, snakebar: true, severity: "success" })
                setTimeout(() => {
                        this.componentDidMount()
                    }, 2500);
            } else {
                this.setState({ message: "Can not delete Focal Point", snakebar: true, severity: "error" })
            }
        })

    }
    changeStatus = (data) => {//For delete selected category
        let id = data.id;
        if (data.status === "Active") {
            FocalPointService.deactiveFocalPoint(data.id).then(res => {
                if (res.status == 200) {
                    this.setState({ message: res.data.message, snakebar: true, severity: "success", officeeditmodel: false })
                    setTimeout(() => {
                        this.componentDidMount()
                    }, 2500);
                } else {
                    this.setState({ message: "Can not edit  Focal Point", snakebar: true, severity: "error" })
                }
            });
        } else {


            data["status"] = "Active";
            FocalPointService.editFocalPoint(id, data).then(res => {
                if (res.status == 200) {
                    this.setState({ message: res.data.message, snakebar: true, severity: "success", officeeditmodel: false })
                    setTimeout(() => {
                        this.componentDidMount()
                    }, 2500);
                } else {
                    this.setState({ message: "Can not edit Focal Point", snakebar: true, severity: "error" })
                }
            });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <SimpleCard title="Focal Point">
                    <TableContainer component={Paper}>
                        <Table style={{ minWidth: 700, tableLayout: "auto" }} stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Department Name</TableCell>
                                    <TableCell align="center">Department Code</TableCell>
                                    <TableCell align="center">Email</TableCell>
                                    <TableCell align="center">Postal Address</TableCell>
                                    <TableCell align="center">Phone Number</TableCell>
                                    <TableCell align="center">Fax Number</TableCell>
                                    <TableCell align="center">Subject Area</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.focalpointdepartments.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="center">{row.code}</TableCell>
                                        <TableCell align="center">{row.email}</TableCell>
                                        <TableCell align="center">{row.address}</TableCell>
                                        <TableCell align="center">{row.contact_no == 0 ? "Not provided" : row.contact_no}</TableCell>
                                        <TableCell align="center">{row.fax_no}</TableCell>
                                        <TableCell align="center">{row.subject_area}</TableCell>
                                        <TableCell align="center">{row.status}</TableCell>
                                        <TableCell align="center" style={{ minWidth: 100 }}>

                                            <Tooltip title="Edit" arrow placement="right">
                                                <IconButton aria-label="edit" onClick={() => { this.handleopen(row); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={row.status === "Active" ? "Delete" : "Recover"} arrow placement="right">
                                                <Switch
                                                    checked={row.status === "Active" ? true : false}
                                                    onClick={() => { this.changeStatus(row); }}
                                                    color="primary"
                                                    size="small"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </Tooltip>
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                    <Dialog fullWidth={true} onClose={() => { this.handleDialogClose(); }} aria-labelledby="customized-dialog-title" open={this.state.officeeditmodel}>
                        <div className="pb-30 px-30 w-90">
                            <DialogTitle id="customized-dialog-title">
                                Edit focal point
                            </DialogTitle>
                            <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            className="mb-24 w-100"
                                            variant="outlined"
                                            label="Department Name"
                                            onChange={this.handleChange}
                                            size='small'
                                            name="name"
                                            value={this.state.formdata.name}
                                            error
                                            helperText="Required Field "
                                            validators={["required"]}
                                            errorMessages={[
                                                "this field is required"
                                            ]}
                                        />
                                        <TextValidator
                                            className="mb-24 w-100"
                                            variant="outlined"
                                            label="Department Code"
                                            onChange={this.handleChange}
                                            size='small'
                                            name="code"
                                            value={this.state.formdata.code}
                                            error
                                            helperText="Required Field "
                                            validators={["required"]}
                                            errorMessages={[
                                                "this field is required"
                                            ]}
                                        />
                                        <TextValidator
                                            className="mb-24 w-100"
                                            variant="outlined"
                                            label="Postal Address"
                                            onChange={this.handleChange}
                                            size='small'
                                            name="address"
                                            value={this.state.formdata.address}
                                            error
                                            helperText="Required Field "
                                            validators={["required"]}
                                            errorMessages={[
                                                "this field is required"
                                            ]}
                                        />
                                        <TextValidator
                                            className="mb-24 w-100"
                                            variant="outlined"
                                            label="Phone Number"
                                            onChange={this.handleChange}
                                            size='small'
                                            // type="number"
                                            name="contact_no"
                                            value={this.state.formdata.contact_no}
                                            validators={["required"]}
                                            errorMessages={[
                                                "this field is required"
                                            ]}
                                        />
                                    </Grid>


                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            className="mb-24 w-100"
                                            variant="outlined"
                                            label="Email"
                                            onChange={this.handleChange}
                                            size='small'
                                            type="email"
                                            name="email"
                                            value={this.state.formdata.email}

                                        />
                                        <TextValidator
                                            className="mb-24 w-100"
                                            variant="outlined"
                                            label="Fax Number"
                                            onChange={this.handleChange}
                                            size='small'
                                            name="fax_no"
                                            value={this.state.formdata.fax_no}
                                            errorMessages={[
                                                "this field is required"
                                            ]}
                                        />
                                        <TextValidator
                                            className="mb-24 w-100"
                                            variant="outlined"
                                            label="Subject Area"
                                            onChange={this.handleChange}
                                            size='small'
                                            name="subject_area"
                                            value={this.state.formdata.subject_area}
                                            error
                                            helperText="Required Field "
                                            validators={["required"]}
                                            errorMessages={[
                                                "this field is required"
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button variant="contained" type="submit" color="primary" style={{ margin: 15 }}>Add</Button>
                                </Grid>


                            </ValidatorForm>
                        </div>
                    </Dialog>
                    <Grid>

                    </Grid>
                </SimpleCard>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.snakebar} autoHideDuration={1000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity={this.state.severity}>
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        );
    };

}

Listfocalpoint.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Listfocalpoint);