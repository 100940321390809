import React, { Component, Fragment } from "react";

import { withStyles } from "@material-ui/styles";
import  Addreferalpoints from "./Addreferalpoints";
import ReferalpointList from "./ReferalpointList";

class Referalpoints extends Component {
  state = {};

  render() {
    let { theme } = this.props;

    return (
      <Fragment>
          <div className="pb-10 pt-30 px-30 ">
          <ReferalpointList/>
        </div>
        <div className="pb-10 pt-30 px-30 ">
        < Addreferalpoints/>
         
        </div>
      </Fragment>
    );
  }
}



export default withStyles({}, { withTheme: true })(Referalpoints);
