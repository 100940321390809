import axios from 'axios';
import * as appconst from '../appconst';

const api_key = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmNDM0NjZhMC1kMjBmLTExZWMtYTM1Ni01MTcwNTlhZGM1MzkiLCJzdWIiOiJTSE9VVE9VVF9BUElfVVNFUiIsImlhdCI6MTY1MjM3MjY1MCwiZXhwIjoxOTY3OTkxODUwLCJzY29wZXMiOnsiYWN0aXZpdGllcyI6WyJyZWFkIiwid3JpdGUiXSwibWVzc2FnZXMiOlsicmVhZCIsIndyaXRlIl0sImNvbnRhY3RzIjpbInJlYWQiLCJ3cml0ZSJdfSwic29fdXNlcl9pZCI6IjY3ODg5Iiwic29fdXNlcl9yb2xlIjoidXNlciIsInNvX3Byb2ZpbGUiOiJhbGwiLCJzb191c2VyX25hbWUiOiIiLCJzb19hcGlrZXkiOiJub25lIn0.AZ2sGCPbzGC3HoVa7Kus4qIH83bZYB1-_OasXDoLP5E"

const send_otp = "https://api.getshoutout.com/otpservice/send"
const check_otp = "https://api.getshoutout.com/otpservice/verify"




class ExternalRequestService {

    get_timeline = async (id) => {
        const headers = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.GET_TIMELINE_BY_CASE_ID_EXTERNAL + id, headers)
                .then(res => {
                    return resolve(res.data.CaseLog);
                })
                .catch((error) => {
                    console.log("error", error);
                    return resolve(error.response.status);
                })
        });
        return await a;
    }

    get_case = async (params) => {

        const headers = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.GET_CASE_BY_CASE_NO_EXTERNAL, headers)
                .then(res => {
                    return resolve(res.data.Case);
                })
                .catch((error) => {
                    console.log("error", error);
                    return resolve(error.response.status);
                })
        });
        return await a;
    }

    find_case = async (params) => {

        const headers = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.CHECK_CASE_NO_IS_EXCIST_EXTERNAL, headers)
                .then(res => {
                    return resolve(res.data.Case);
                })
                .catch((error) => {
                    console.log("error", error);
                    return resolve(error.response.status);
                })
        });
        return await a;
    }

    match_mobile = async (params) => {

        const headers = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: params
        };
        const a = new Promise((resolve, reject) => {
            axios.get(appconst.MATCH_MOBILE_EXTERNAL, headers)
                .then(res => {
                    return resolve(res.data.Case);
                })
                .catch((error) => {
                    console.log("error", error);
                    return resolve(error.response.status);
                })
        });
        return await a;
    }

    sendOtp = async (params) => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Apikey ${api_key}`)
        myHeaders.append("Content-Type", "application/json")

        var raw = JSON.stringify({
            "source": "HRC SL",
            "transport": "sms",
            "content": {
                "sms": "This is your OTP {{code}}"
            },
            "destination": "94" + params
        });


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        const a = fetch(send_otp, requestOptions)
            .then(response => response.json())
            .then(result => {
                return result;
            })
            .catch((error) => {
                console.log("error", error);
                return error;
            })

        return await a;
    }

    checkOTP = async (params) => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Apikey ${api_key}`)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("Accept", "application/json")

        var raw = JSON.stringify({
            "code": params.otp,
            "referenceId": params.referenceId
        });


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        const a = fetch(check_otp, requestOptions)
            .then(response => response.json())
            .then(result => {
                return result;
            })
            .catch((error) => {
                console.log("error", error);
                return error;
            })

        return await a;

    }
}
export default new ExternalRequestService();