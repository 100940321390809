import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const Summon = MatxLoadable({
  loader: () => import("./Summon")
})

const SummonView = MatxLoadable({
  loader: () => import("./SummonView")
})

const SummonSectionRoutes = [
  {
    path: "/summon/section",
    component: Summon,
    auth: authRoles.SummonSection
  },
  {
    path: "/summon/view/:id",
    component: SummonView,
    auth: authRoles.SummonSection
  },
];

export default SummonSectionRoutes;
