import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const TemporyCaseslist = MatxLoadable({
  loader: () => import("./TemporyCaseslist")
})
const TemporyCasesAdmiin = MatxLoadable({
  loader: () => import("./TemporyCaseallDetailsAdmin")
})
const TemporyCaseSingle = MatxLoadable({
  loader: () => import("./TemporyCaseSingle")
})


const TemporryCasesRoutes = [
  {
    path: "/TemporyCasesall",
    component: TemporyCaseslist,
    auth: authRoles.temporrycases
  },
  {
    path: "/TemporyCases/all",
    component: TemporyCasesAdmiin,
    auth: authRoles.temporrycases
  },
  {
    path: "/TemporyCasesSingle/:id",
    component: TemporyCaseSingle,
    auth: authRoles.temporrycases
  }
];



export default TemporryCasesRoutes;
