import { MatxLoadable } from "matx";
import { authRoles } from "../../../../auth/authRoles";

const NotAccept = MatxLoadable({
  loader: () => import("./NotAcceptCase")
})

const NotAcceptCaseRoutes = [
  {
    path: "/NotAcceptCase/Director/:id",
    component: NotAccept,
    auth: authRoles.directorCannotCase
  }
];

export default NotAcceptCaseRoutes;