import React, { Component } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { SimpleCard } from "matx";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import { withStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import ReferalPointService from './../../../services/ReferalPointService';

const styles = theme => ({
    cssLabel: {
        color: 'red'
    },

    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `${theme.palette.primary.main} !important`,
        }
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'blue !important'
    },

});
class Addreferalpoints extends Component {


    state = {
        snakebar: false,
        message: "",
        severity: "success",
        formdata: { name: "", address: "", email: "", contact_no: "" }
    };


    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snakebar: false })

    };
    handleChange = event => {
        event.persist();
        var data = this.state.formdata;

        data[event.target.name] = event.target.value;
        this.setState({
            formdata: data
        });

    };
    handleFormSubmit = event => {
        var data = this.state.formdata;

        ReferalPointService.createReferalPoint(data).then(res => {
            if (res.status == 200) {
                this.setState({ message: res.data.message, snakebar: true, severity: "success" })
                setTimeout(() => {
                    window.location.reload(false);
                }, 3000);
            } else {
                this.setState({ message: "Can not create referal point", snakebar: true, severity: "error" })
            }
        })
            .catch((error) => {
                console.log("error", error);
            })

    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <SimpleCard title="Referral Points">
                    <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={3} >
                            <Grid item xs={12} sm={6}>
                                <TextValidator
                                    className="mb-24 w-100"
                                    variant="outlined"
                                    label="Name"
                                    onChange={this.handleChange}
                                    size='small'
                                    name="name"
                                    value={this.state.formdata.name}
                                    error
                                    helperText="Required Field "
                                    validators={["required"]}
                                    errorMessages={[
                                        "this field is required"
                                    ]}
                                />
                                <TextValidator
                                    className="mb-24 w-100"
                                    variant="outlined"
                                    label="Postal Addresss"
                                    onChange={this.handleChange}
                                    size='small'
                                    name="address"
                                    value={this.state.formdata.address}
                                    error
                                    helperText="Required Field "
                                    validators={["required"]}
                                    errorMessages={[
                                        "this field is required"
                                    ]}
                                />



                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    className="mb-24 w-100"
                                    variant="outlined"
                                    label="Email"
                                    onChange={this.handleChange}
                                    size='small'
                                    type="email"
                                    name="email"
                                    value={this.state.formdata.email}

                                />
                                <TextValidator
                                    className="mb-24 w-100"
                                    variant="outlined"
                                    label="Contact Number"
                                    onChange={this.handleChange}
                                    size='small'
                                    name="contact_no"
                                    value={this.state.formdata.contact_no}
                                    error
                                    helperText="Required Field "
                                    validators={["required"]}
                                    errorMessages={[
                                        "this field is required"
                                    ]}
                                />
                            </Grid>

                        </Grid>

                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            type="submit"
                            startIcon={<SaveIcon />}
                        >
                            Save
                        </Button>
                    </ValidatorForm>

                </SimpleCard>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.snakebar} autoHideDuration={2500} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity={this.state.severity}>
                        {this.state.message}
                    </Alert>
                </Snackbar>

            </div>
        );
    };

}

Addreferalpoints.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Addreferalpoints);
